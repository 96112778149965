const PHONE_WIDTH = 480;
export const TABLET_WIDTH = 800;
export const WIDE_TABLET_WIDTH = 1024;

export enum deviceTypeEnum {
  PHONE = "phone",
  TABLET = "tablet",
  DESKTOP = "desktop",
}

export const getDeviceType = (width: number): deviceTypeEnum => {
  if (width <= PHONE_WIDTH) {
    return deviceTypeEnum.PHONE;
  }

  if (width <= TABLET_WIDTH) {
    return deviceTypeEnum.TABLET;
  }

  return deviceTypeEnum.DESKTOP;
};

export const getWindowWidth = (): number => {
  const { innerWidth: width } = window;
  return width;
};
