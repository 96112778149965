import { useLocation, useNavigation } from "react-router-dom";
import { useEffect, useState } from "react";

const WITHOUT_NAVIGATION = [
  "/ifr/",
  "/auth"
];

export const useLessNavigation = () => {
  const [canShow, setCanShow] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    for (const value in WITHOUT_NAVIGATION) {
      const result = pathname.indexOf(WITHOUT_NAVIGATION[value]) === 0;

      if (result) {
        setCanShow(!result);
        return;
      }
    }

    setCanShow(true);
  }, [pathname]);

  return {
    canShow
  };
};
