import { useLocation, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { routes } from "routes";
import { IKindeLocationState } from "services/kinde/kindeService";

export const useAuthNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getOptions = () => {
    const state = location.state as IKindeLocationState | undefined;
    return state?.redirectTo ? { state: { redirectTo: state.redirectTo } } : { state: { redirectTo: window.location.href } };
  };

  const navigateSignIn = useCallback(() => {
    navigate(routes.userSignIn(), getOptions());
  }, []);

  const navigateSignUp = useCallback(() => {
    navigate(routes.userSignUp(), getOptions());
  }, []);

  return {
    navigateSignIn,
    navigateSignUp,
  };
};
