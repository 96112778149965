import { IShortUserList, IUserSearchResults } from "./types";

import AuthHttpService from "./AuthHttpService";

export default class CreatorsService extends AuthHttpService {
  public constructor(readonly url?: string) {
    super(url);
  }

  async searchCreators(params: {
    order: string;
    page: number;
    tags?: string;
    verified?: boolean;
  }): Promise<IUserSearchResults> {
    const query = new URLSearchParams();

    query.append("order", params.order);
    query.append("page", params.page.toString());

    if (params.verified) {
      query.append("verified", "y");
    }

    if (params.tags) {
      query.append("tags", params.tags);
    }

    return await this.get(`v1/creators/search?${query}`);
  }

  async getNewVerifiedCreators(): Promise<IShortUserList> {
    return await this.get("v2/creators/verified");
  }

  async getTrendingCreators(): Promise<IUserSearchResults> {
    return await this.get("v1/creators/trending");
  }
}
