import { CloseButton } from "components/buttons/CloseButton";
import { Fragment, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import classes from "./UploadHeader.module.scss";

export const UploadHeader = () => {
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Fragment>
      <p className={classes.navUpload}>Upload</p>
      <CloseButton onClick={handleClose} />
    </Fragment>
  );
};
