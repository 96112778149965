import { createRef, memo, RefObject, Suspense, VideoHTMLAttributes } from "react";
import { HlsVideo } from "../HlsVideo";
import { useVideo } from "./hooks";

interface IProps
  extends VideoHTMLAttributes<HTMLVideoElement> {
  videoRef?: RefObject<HTMLVideoElement>;
  onMounted?: () => void;
}

export const Video = memo(({
  src,
  videoRef = createRef<HTMLVideoElement>(),
  ...restProps
}: IProps) => {
  const { isHls } = useVideo({ src });

  return (
    <Suspense>
      <HlsVideo videoRef={videoRef} src={src} useHlsEngine={isHls} {...restProps} />
    </Suspense>
  );
});
