import {
  IGetRelatedNicheParams,
  IGifList,
  INiche,
  INicheFeedbackRequest,
  INicheFeedbackResponse,
  INicheList,
  IAddToNicheParams, IShortUserList
} from "./types";

import AuthHttpService from "./AuthHttpService";

export default class NichesService extends AuthHttpService {
  public constructor(readonly url?: string) {
    super(url);
  }

  async editNiche(params: {
    id: string;
    description: string;
    rules: string;
    image: string | null;
  }): Promise<void> {
    return await this.patch(`/v2/niches/${params.id}`, {
      description: params.description,
      rules: params.rules,
      image: params.image
    });
  }

  async createNiche(params: {
    name: string;
    description: string;
    rules: string;
    profile_image: string; // upload ticket id
  }): Promise<void> {
    return await this.post("v2/niches", {
      name: params.name,
      description: params.description,
      rules: params.rules,
      profile_image: params.profile_image,
    });
  }

  async getNiche(id: string): Promise<INiche> {
    return (await this.get<Promise<{niche: INiche}>>(`v2/niches/${id}`)).niche;
  }

  async getNiches(props: {
    order?: string;
    previews?: boolean;
    tag?: string;
  }): Promise<INicheList> {
    const query = new URLSearchParams();

    if (props.previews) {
      query.append("previews", "yes");
    }

    if (props.order) {
      query.append("order", props.order);
    }

    if (props.tag) {
      query.append("tag", props.tag);
    }

    return await this.get(`v2/niches?${query.toString()}`);
  }

  async setGifNiches(props: {
    gifId: string;
    nicheIds: string[];
  }): Promise<void> {
    await this.put(`v2/gifs/${props.gifId}/niches`, {
      niches: props.nicheIds,
    });
  }

  async getFollowingNiches(props: {
    previews: boolean;
  }): Promise<INicheList> {
    const query = new URLSearchParams();

    if (props.previews) {
      query.append("previews", "yes");
    }

    return await this.get(`v2/niches/following?${query.toString()}`);
  }

  async getMyNiches(props: {
    previews: boolean;
  }): Promise<INicheList> {
    const query = new URLSearchParams();

    if (props.previews) {
      query.append("previews", "yes");
    }

    return await this.get(`v2/niches/my?${query.toString()}`);
  }

  async getTrendingNiches(previews?: boolean): Promise<INicheList> {
    const query = new URLSearchParams();

    if (previews) {
      query.append("previews", "yes");
    }

    return await this.get(`v2/niches/trending?${query.toString()}`);
  }

  async getSuggestedNiches(): Promise<INiche[]> {
    return (await this.get<Promise<INicheList>>("v2/niches/suggest")).niches;
  }

  async addToNiche(params: IAddToNicheParams) {
    return await this.post(`v2/niches/${params.niche_id}/gifs`, { id: params.gif_id });
  }

  async follow(id: string): Promise<void> {
    await this.post(`v2/niches/${id}/subscription`);
  }

  async unfollow(id: string): Promise<void> {
    await this.delete(`v2/niches/${id}/subscription`);
  }

  async getNicheGifs(params: {
    id: string;
    page: number;
    count: number;
    type?: string|undefined;
    order: string;
  }): Promise<IGifList> {
    let url = `v2/niches/${params.id}/gifs?count=${params.count}&page=${params.page}&order=${params.order}`;

    if (params.type !== undefined) {
      url += `&type=${params.type}`;
    }

    return await this.get(url);
  }

  async reportBrokenThumbnail(params: {
    niche: string;
    gif: string;
  }): Promise<void> {
    await this.post(`v2/niches/${params.niche}/missing-gif`, {
      gif: params.gif,
    });
  }

  /**
   * Send a feedback for a gif.
   * Valid state values: up, down.
   */
  async addFeedback(params: INicheFeedbackRequest): Promise<INicheFeedbackResponse> {
    return await this.post(`v2/niches/${params.niche}/feedback`, {
      gif: params.gif,
      state: params.state,
    });
  }

  /**
   * Get feedback for a niche.
   */
  async getFeedback(niche: string) {
    return await this.get<Promise<INicheFeedbackResponse[]>>(`v2/niches/${niche}/feedback`);
  }

  async getRelatedNiches({ niche }: IGetRelatedNicheParams) {
    return await this.get<Promise<INicheList>>(`v2/niches/${niche}/related`);
  }

  async getNichesTags({ niche }: IGetRelatedNicheParams) {
    return await this.get<Promise<{ tags: string[] }>>(`v2/niches/${niche}/top-tags`);
  }

  async getNichesCreators({ niche }: IGetRelatedNicheParams) {
    return await this.get<Promise<IShortUserList>>(`v2/niches/${niche}/top-creators`);
  }

  async getGifSuggestedNiche(id: string) {
    return await this.get<Promise<INicheList>>(`v2/gifs/${id}/niches/suggest`);
  }

  async getAddGifSuggestedNiches(tags: string[]) {
    const query = new URLSearchParams();

    if (tags.length > 0) {
      query.append("tags", tags.join(","));
    }

    return await this.get<Promise<INicheList>>(`v2/niches/suggest/adding?tags=${query.toString()}`);
  }
}
