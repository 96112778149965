import sessionService from "services/session/sessionService";
import seedrandom from "seedrandom";

export const isTrackingEnabled = async (trackingRatio: number) => {
  const sessionId = (await sessionService.getSession()).session;
  const getSessionScore = seedrandom(sessionId);
  const scoreResult = getSessionScore();

  return scoreResult <= trackingRatio;
};
