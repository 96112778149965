/**
 * Manage the list of recently viewed cameras in the local storage.
 */

const SESSION_STORAGE_KEY = "streamate_camera_ids";

export const addCamera = (id: number) => {
  try {
    const cameras = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY) || "[]") as number[];

    if (!cameras.includes(id)) {
      cameras.push(id);
      sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(cameras));

      console.debug(`Streamate camera ${id} added to the list of recently displayed cameras.`);
    }
  } catch (e) {
    console.error(`Error adding camera ${id} to session storage.`);
  }
};

export const resetCameras = () => {
  try {
    sessionStorage.setItem(SESSION_STORAGE_KEY, "[]");
  } catch (e) {
    console.error("Error resetting the list of Streamate cameras.");
  }
};

export const getCameras = (): number[] => {
  try {
    return JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY) || "[]");
  } catch (e) {
    console.error("Error reading the list of Streamate cameras.");
    return [];
  }
};
