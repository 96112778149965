/**
 * An interface for sending events to GA4.
 *
 * Note that all events must be added to the GTM console.
 * Sending an event with an unknown name produces no results,
 * no requests, hard to debug.
 *
 * Event names are defined here:
 * https://tagmanager.google.com/#/container/accounts/6000141753/containers/30150878/workspaces/20/tags
 *
 * Parameter names are also important, defined here:
 * https://tagmanager.google.com/#/container/accounts/6000141753/containers/30150878/workspaces/20/variables
 */

import { apiMain } from "services/index";
import { isAuthenticated } from "services/Auth";
import {
  AddedToCollectionEvent,
  AdLoadedEvent,
  FollowUserClickedEvent,
  GifClickedEvent,
  GifLikedEvent,
  GifViewEvent,
  GifReplayEvent,
  MeaningfulGifViewEvent,
  MenuButtonClickedEvent,
  ProfileViewedEvent,
  SearchedEvent,
  SocialProfileClickedEvent,
  TagClickedEvent,
  UploadFinishedEvent,
} from "buses";
import { isPrivate } from "utils/detectIncognito";
import experimentService from "services/experiment/experimentService";
import { useUserStore } from "store";

interface eventData {
  [key: string]: string | string[] | number | boolean | null | undefined;
}

declare global {
  interface Window {
    dataLayer: eventData[];
  }
}

class GaMetrics {
  async sendAddedToCollection(props: AddedToCollectionEvent): Promise<void> {
    await this.sendRawEvent({
      event: "addedToCollection",
      gifId: props.gifId,
    });
  }

  /**
   * Send when the ad script loaded successfully.
   * Not for tracking individual ads.
   */
  async sendAdLoaded(props: AdLoadedEvent): Promise<void> {
    await this.sendRawEvent({
      event: "AdLoaded",
      isAdLoaded: props.isLoaded,
    });
  }

  async sendEncodingFinished(): Promise<void> {
    await this.sendRawEvent({
      event: "encodingFinished",
    });
  }

  async sendEncodingStarted(props: {
    sourceURL?: string;
  }): Promise<void> {
    await this.sendRawEvent({
      event: "encodingStarted",
      sourceUrl: props.sourceURL,
    });
  }

  async sendFollowUserClicked(props: FollowUserClickedEvent): Promise<void> {
    await this.sendRawEvent({
      event: "followClicked",
      username_clicked: props.userName,
      username_owner: await this.getUserName(),
    });
  }

  async sendGifClick(props: GifClickedEvent): Promise<void> {
    await this.sendRawEvent({
      event: "gifClick",
      source: props.source,
      position: props.position,
    });
  }

  async sendGifView(props: GifViewEvent): Promise<void> {
    await this.sendRawEvent({
      event: "gifView",
      gifId: props.gifId,
      tags: props.tags,
      source: props.source,
    });
  }

  async sendMeaningfulGifView(props: MeaningfulGifViewEvent): Promise<void> {
    await this.sendRawEvent({
      event: "meaningfulGifView",
      gifId: props.gifId,
    });
  }

  async sendGifReplay(props: GifReplayEvent): Promise<void> {
    await this.sendRawEvent({
      event: "gifReplay",
      gifId: props.gifId,
      tags: props.tags
    });
  }

  async sendLiked(props: GifLikedEvent): Promise<void> {
    await this.sendRawEvent({
      event: "liked",
      gifId: props.gifId,
    });
  }

  async sendMenuButtonClicked(props: MenuButtonClickedEvent): Promise<void> {
    await this.sendRawEvent({
      event: "menuButtonClicked",
      buttonName: props.name,
    });
  }

  async sendProfileView(props: ProfileViewedEvent): Promise<void> {
    await this.sendRawEvent({
      event: "profileView",
      username_clicked: await this.getUserName(),
      username_owner: props.userName,
    });
  }

  async sendSearch(props: SearchedEvent): Promise<void> {
    await this.sendRawEvent({
      event: "search",
      query: props.query,
      count: props.totalCount,
    });
  }

  async sendSignUpFormView(): Promise<void> {
    await this.sendRawEvent({
      event: "signUpView",
    });
  }

  async sendSignUpSuccessful(): Promise<void> {
    await this.sendRawEvent({
      event: "signUpSuccess",
    });
  }

  async sendSocialProfileClick(props: SocialProfileClickedEvent): Promise<void> {
    await this.sendRawEvent({
      event: "socialProfileClicked",
      username_clicked: await this.getUserName(),
      username_owner: props.userName,
    });
  }

  async sendTagClicked(props: TagClickedEvent): Promise<void> {
    await this.sendRawEvent({
      event: "tagClicked",
      tagName: props.tag,
    });
  }

  async sendUploadFinisher(props: UploadFinishedEvent): Promise<void> {
    await this.sendRawEvent({
      event: "uploadFinished",
      source: props.sourceURL,
    });
  }

  async sendUploadStarted(): Promise<void> {
    await this.sendRawEvent({
      event: "uploadStarted",
      context: "create",
    });
  }

  async sendUploadUrlSelected(): Promise<void> {
    await this.sendRawEvent({
      event: "uploadUrlSelected"
    });
  }

  async sendUploadImageSelected(): Promise<void> {
    await this.sendRawEvent({
      event: "uploadImageSelected"
    });
  }

  async sendUploadImageConfirmed(): Promise<void> {
    await this.sendRawEvent({
      event: "uploadImageConfirmed"
    });
  }

  async sendUploadVideoSelected(): Promise<void> {
    await this.sendRawEvent({
      event: "uploadVideoSelected"
    });
  }

  async sendUploadVideoTrim(): Promise<void> {
    await this.sendRawEvent({
      event: "uploadVideoTrim"
    });
  }

  async sendUploadVideoConfirmed(): Promise<void> {
    await this.sendRawEvent({
      event: "uploadVideoConfirmed"
    });
  }

  async sendUploadAudienceDefined(): Promise<void> {
    await this.sendRawEvent({
      event: "uploadAudienceDefined"
    });
  }

  async sendUploadTagsSelected(): Promise<void> {
    await this.sendRawEvent({
      event: "uploadTagsSelected"
    });
  }

  async sendUploadGetVerifiedClicked(): Promise<void> {
    await this.sendRawEvent({
      event: "uploadGetVerifiedClicked"
    });
  }

  async sendUploadPublished(): Promise<void> {
    await this.sendRawEvent({
      event: "uploadPublished"
    });
  }

  async sendUploadMultipleConfirmed(): Promise<void> {
    await this.sendRawEvent({
      event: "uploadMultipleConfirmed"
    });
  }

  async sendUploadVideoDropped(): Promise<void> {
    await this.sendRawEvent({
      event: "uploadVideoDropped"
    });
  }

  async sendUploadImageDropped(): Promise<void> {
    await this.sendRawEvent({
      event: "uploadImageDropped"
    });
  }

  async sendUploadImagesDropped(): Promise<void> {
    await this.sendRawEvent({
      event: "uploadImagesDropped"
    });
  }

  async sendOptionClick(): Promise<void> {
    await this.sendRawEvent({
      event: "optionClick"
    });
  }

  async sendShareClick(): Promise<void> {
    await this.sendRawEvent({
      event: "shareClick"
    });
  }

  async sendShareCopy(): Promise<void> {
    await this.sendRawEvent({
      event: "shareCopy"
    });
  }

  async sendShareEmbed(): Promise<void> {
    await this.sendRawEvent({
      event: "shareEmbed"
    });
  }

  async sendShareReddit(): Promise<void> {
    await this.sendRawEvent({
      event: "shareReddit"
    });
  }

  async sendShareTwitter(): Promise<void> {
    await this.sendRawEvent({
      event: "shareTwitter"
    });
  }

  async sendShareDiscord(): Promise<void> {
    await this.sendRawEvent({
      event: "shareDiscord"
    });
  }

  async sendShareEmbedFixed(): Promise<void> {
    await this.sendRawEvent({
      event: "shareEmbedFixed"
    });
  }

  async sendShareEmbedResponsive(): Promise<void> {
    await this.sendRawEvent({
      event: "shareEmbedResponsive"
    });
  }

  async sendEnterFullscreen(): Promise<void> {
    await this.sendRawEvent({
      event: "enterFullscreen"
    });
  }

  async sendScrollPromptShow(): Promise<void> {
    await this.sendRawEvent({
      event: "scrollPromptShow"
    });
  }

  async sendScrollPromptDismiss(): Promise<void> {
    await this.sendRawEvent({
      event: "scrollPromptDismiss"
    });
  }

  async sendUploadBulkClick(): Promise<void> {
    await this.sendRawEvent({
      event: "uploadBulkClick"
    });
  }

  async sendSessionStartPrivate(): Promise<void> {
    await this.sendRawEvent({
      event: "sessionStartPrivate"
    });
  }

  async sendInfobarCta(): Promise<void> {
    await this.sendRawEvent({
      event: "infobarСta"
    });
  }

  async sendInfobarDismiss(): Promise<void> {
    await this.sendRawEvent({
      event: "infobarDismiss"
    });
  }

  async sendPinnedContentClick(): Promise<void> {
    await this.sendRawEvent({
      event: "pinnedContentClick"
    });
  }

  async sendWatch120s(): Promise<void> {
    await this.sendRawEvent({
      event: "watched120s"
    });
  }

  async sendVoteUpAddClick(): Promise<void> {
    await this.sendRawEvent({
      event: "voteUpAddClick"
    });
  }

  async sendVoteUpRemoveClick(): Promise<void> {
    await this.sendRawEvent({
      event: "voteUpRemoveClick"
    });
  }

  async sendVoteDownAddClick(): Promise<void> {
    await this.sendRawEvent({
      event: "voteDownAddClick"
    });
  }

  async sendVoteDownRemoveClick(): Promise<void> {
    await this.sendRawEvent({
      event: "voteDownRemoveClick"
    });
  }

  async sendErrorLoadingGifs(): Promise<void> {
    await this.sendRawEvent({
      event: "sendErrorLoadingGifs"
    });
  }

  async sendTagFeedbackEnabled(): Promise<void> {
    await this.sendRawEvent({
      event: "tagFeedbackEnabled"
    });
  }

  async sendTagFeedbackEnabledOptions(): Promise<void> {
    await this.sendRawEvent({
      event: "tagFeedbackEnabledOptions"
    });
  }

  async sendRawEvent(event: eventData) {
    window.dataLayer = window.dataLayer ? window.dataLayer : [];

    event.userLogIn = await this.isUserLoggedIn();
    event.userName = this.getUserName();
    event.userType = this.getUserType();
    event.userVerified = this.getUserVerifiedStatus();
    event.experiments = await this.getExperiments();
    event.fullscreen = this.isFullscreen();
    event.isPrivate = await isPrivate();

    const res = window.dataLayer.push(event);

    // @ts-ignore
    if (res === true) {
      console.warn(`[GTM] Event not set, make sure that "${event.event}" is added properly`
        + " in the console here: https://tagmanager.google.com/#/container/accounts/6000141753/containers/30150878/workspaces/20/tags", event);
    } else {
      console.debug("[GTM] Event sent.", event);
    }
  }

  async isUserLoggedIn(): Promise<boolean> {
    try {
      return await isAuthenticated();
    } catch (e) {
      console.warn("[GTM] Could not tell if the user is authenticated, assuming no.");
      return false;
    }
  }

  async getExperiments() {
    const variants = await experimentService.getAllVariants();

    return Object.keys(variants).filter((key: string) => variants[key]);
  }

  getUserName(): string | null {
    const user = useUserStore.getState().currentUser;
    return user?.username ?? null;
  }

  getUserType(): string | null {
    const user = useUserStore.getState().currentUser;
    if (!user?.username) {
      return null;
    }

    if (user.verified) {
      return "verifiedCreator";
    }

    if (user.gifs > 0) {
      return "creator";
    }

    return "consumer";
  }

  getUserVerifiedStatus(): boolean | null {
    const user = useUserStore.getState().currentUser;
    if (!user?.username) {
      return null;
    }

    return user.verified;
  }

  isFullscreen(): boolean {
    return !!document.querySelector("#root")?.classList?.contains("fullScreen");
  }
}

export default new GaMetrics();
