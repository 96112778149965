import { ErrorInfo } from "react";

interface IProps {
  error: Error;
  info: ErrorInfo;
  clearError: () => void;
}

const ErrorBoundaryView = ({ error, info, clearError }: IProps) => {
  return (
    <div>
      <p>Something went wrong.</p>

      {
        import.meta.env.MODE === "development" && (
          <div>
            <details>
              <summary>{error.toString()}</summary>
              {
                info.componentStack.split("\n").map((row, index) => <div key={index}>{row}</div>)
              }
            </details>
            <button onClick={clearError}>Clear</button>
          </div>
        )
      }
    </div>
  );
};

export default ErrorBoundaryView;
