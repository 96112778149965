import "./buttons.scss";

import CloseIcon from "../icons/closeWhite.svg?react";

export const CloseButton = (props: {
  onClick: () => void;
  id?: string;
}): JSX.Element => {
  return (
    <button
      id={props.id}
      className="rg-button more"
      title="Close"
      onClick={props.onClick}
      aria-label="close"
    >
      <CloseIcon />
    </button>
  );
};
