import RgLogo from "components/icons/RGLogo.svg?react";
import "./RGLogo.scss";

export const RGLogo = () => {
  return (
    <a href="/" className="mainLogo" title="Back to home page" aria-label="Link to home page">
      <RgLogo />
    </a>
  );
};
