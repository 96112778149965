import ErrorBoundaryView from "components/ErrorBoundaryView";
import { QueryClient, QueryClientProvider } from "react-query";
import React, { Suspense } from "react";
import Bugsnag from "../../../bugsnag";
import { App } from "components/app/App";

// @ts-ignore
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

export const AppContainer = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryView}>
      <QueryClientProvider client={new QueryClient()}>
        <Suspense><App /></Suspense>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};
