import AuthHttpService from "./AuthHttpService";

import {
  ICreatorsItemsList,
  IGifList,
  IHistoryList,
  INichesItems,
  ISearchNicheList,
  ISearchUsersList,
  ITagsList,
  ITrendingTagsList,
} from "./types";

interface ISearchGifsRequest {
  query: string;
  page: number;
  count: number;
  order: "latest" | "trending" | "top" | "top28";
  verified?: boolean;
}

interface ISearchNichesRequest {
  query: string;
  page: number;
  count: number;
  order: "posts" | "subscribers";
}

interface ISearchCreatorsRequest {
  query: string;
  page: number;
  count: number;
  order: "latest" | "trending" | "top";
  verified?: boolean;
}

export default class SearchService extends AuthHttpService {
  public constructor(readonly url?: string) {
    super(url);
  }

  async searchGifs({ query, page, count, order, verified }: ISearchGifsRequest): Promise<IGifList> {
    const params = new URLSearchParams({
      query,
      page: page.toString(),
      count: count.toString(),
      order
    });

    verified && params.append("verified", "yes");

    return await this.get(`v2/search/gifs?${params.toString()}`);
  }

  async searchImages({ query, page, count, order, verified }: ISearchGifsRequest): Promise<IGifList> {
    const params = new URLSearchParams({
      query,
      page: page.toString(),
      count: count.toString(),
      order,
    });

    verified && params.append("verified", "yes");

    return await this.get(`v2/search/images?${params.toString()}`);
  }

  async searchNiches({ query, page, count, order }: ISearchNichesRequest): Promise<ISearchNicheList> {
    const params = new URLSearchParams({
      query,
      page: page.toString(),
      count: count.toString(),
      order,
    });

    return await this.get(`v2/search/niches?${params.toString()}`);
  }

  async searchCreators({ query, page, count, order, verified }: ISearchCreatorsRequest): Promise<ISearchUsersList> {
    const params = new URLSearchParams({
      query,
      page: page.toString(),
      count: count.toString(),
      order,
    });

    verified && params.append("verified", "yes");

    return await this.get(`v2/search/creators?${params.toString()}`);
  }

  async getTrendingSearches(): Promise<ITrendingTagsList> {
    return await this.get("v2/search/trending");
  }

  async getCreatorsSearches(query: string): Promise<ICreatorsItemsList> {
    return await this.get(`v2/creators/suggest?query=${query}`);
  }

  async getNichesSearches(query: string): Promise<INichesItems[]> {
    return await this.get(`v2/niches/search?query=${query}`);
  }

  async getTagsSearches(query: string): Promise<ITagsList[]> {
    return await this.get(`v2/search/suggest?query=${query}`);
  }

  async getHistorySearches(query: string): Promise<IHistoryList[]> {
    return await this.get(`v2/search/history?query=${query}`);
  }

  async getTopThisWeek(): Promise<IGifList> {
    return await this.get("v2/explore/top-this-week");
  }

  async getTrendingGifs(): Promise<IGifList> {
    return await this.get("v2/explore/trending-gifs");
  }

  async getTrendingImages(): Promise<IGifList> {
    return await this.get("v2/explore/trending-images");
  }
}
