import { create } from "zustand";

type TRecommendedFeedState = {
  feedId: string;
  feedGifsIds: string[];
  interactionsEnabled: boolean;
}

type TRecommendedFeedActions = {
  setInteractionsEnabled: (value: boolean) => void;
  setFeedId: (value: string) => void;
  setFeedGifsIds: (value: string[]) => void;
  reset: () => void;
}

const initialState: TRecommendedFeedState = {
  feedId: "",
  feedGifsIds: [],
  interactionsEnabled: false
};

export const useRecommendedFeedStore = create<TRecommendedFeedState & TRecommendedFeedActions>((set) => ({
  ...initialState,
  setFeedId: (value) => set(() => ({ feedId: value })),
  setFeedGifsIds: (value) => set(() => ({ feedGifsIds: value })),
  reset: () => set(initialState),
  setInteractionsEnabled: (value) => set(() => ({ interactionsEnabled: value })),
}));
