import AuthHttpService from "./AuthHttpService";

export interface IUpdateParams {
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

export default class VisitorService extends AuthHttpService {
  public constructor(readonly url?: string) {
    super(url);
  }

  async updateVisitorInfo(params: IUpdateParams[]) {
    return await this.patch("v1/me", { operations: params });
  }

  async verifyUserProfile(verifyData: { [key: string]: string }) {
    return await this.post("v2/me/verify-profile", verifyData);
  }
}
