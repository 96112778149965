import { AdTabProps } from "./tab";

enum AdTabsEnum {
  RGCams="RGCams",
  LiveSex="LiveSex",
}

type AdTabsProps = {
  [name in AdTabsEnum]: AdTabProps;
}

export const tabs: AdTabsProps = {
  [AdTabsEnum.RGCams]: {
    link: "https://www.redgifs.cam/en/?psid=RedGIFs&pstool=205_1&psprogram=cbrnd&utm_source=tab&mr=0",
  },
  [AdTabsEnum.LiveSex]: {
    link: "https://rgcams.com/?AFNO=1-1",
  },
};
