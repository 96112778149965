import { Navigate, Route, Routes } from "react-router-dom";
import { Suspense } from "react";

import {
  AccountSettingsPage,
  AnalyticsPage,
  AuthorizePage,
  CategoriesPage,
  CollectionItemsPage,
  CollectionsPage,
  CrashPage,
  DMCAPage,
  EmbedPage,
  ExperimentsPage,
  ExploreCreatorsPage,
  ExploreGifsPage,
  ExploreImagesPage,
  ExploreNichesPage,
  ExplorePage,
  FAQPage,
  FavouritesPage,
  FollowersPage,
  FollowingPage,
  GuidelinesPage,
  HomePage,
  ManageAdSlotsPage,
  ManagePage,
  MigrateImgurConfirmPage,
  MigrateImgurPage,
  MigrateImgurSuccessPage,
  MyNichesPage,
  NichePage,
  PrivacyPolicyPage,
  ProfileSettingsPage,
  SearchPage,
  FreetypeSearchPage,
  Statement2257Page,
  SudoPage,
  TagPage,
  TermsPage,
  TexasSlatePage,
  UnsubscribedPage,
  UploadPage,
  UserPage,
  VerifyPage,
  WatchPage,
  SearchWarningPage,
  SignInPage,
  SignUpPage,
} from "pages";
import WithAuthRequired from "./WithAuthRequired";
import { routes } from "routes";
import { Loader } from "components/UI/Loader";
import { WithTimer } from "./UI/WithTimer";
import { useUserStore } from "store/userStore";

const RoutesComponents = () => {
  const currentUserName = useUserStore((state) => state.currentUser?.username);

  return (
    <Suspense fallback={<WithTimer waitTime={2000}><Loader /></WithTimer>}>
      <Routes>
        <Route path={routes.crash()} element={<CrashPage />} />
        <Route path={routes.dashboard()} element={<WithAuthRequired><AnalyticsPage /></WithAuthRequired>} />
        <Route path={routes.watch(":id")} element={<WatchPage />} />
        <Route path={routes.embed(":id")} element={<EmbedPage />} />
        <Route path={routes.home()} element={<HomePage />}>
          <Route path={":videoId"} element={<HomePage />} />
          <Route path={""} element={<HomePage />} />
        </Route>
        <Route path={routes.explore()} element={<ExplorePage />} />
        <Route path={routes.exploreCreators()} element={<ExploreCreatorsPage />} />
        <Route path={routes.exploreImages()} element={<ExploreImagesPage />} />
        <Route path={routes.exploreTop()} element={<ExploreGifsPage />} />
        <Route path={routes.niches()} element={<ExploreNichesPage />} />
        <Route path={routes.migrateImgur()} element={<WithAuthRequired><MigrateImgurPage /></WithAuthRequired>} />
        <Route path={routes.migrateImgurConfirm()} element={<WithAuthRequired><MigrateImgurConfirmPage /></WithAuthRequired>} />
        <Route path={routes.migrateImgurSuccess()} element={<WithAuthRequired><MigrateImgurSuccessPage /></WithAuthRequired>} />
        <Route path={routes.myNiches()} element={<WithAuthRequired><MyNichesPage /></WithAuthRequired>} />
        <Route path={routes.favourites()} element={<FavouritesPage />} />

        <Route path={routes.niche(":id")} element={<NichePage />} />
        <Route path={routes.user(":id")} element={<UserPage />} />
        <Route path={routes.userCollections(":userId")} element={<CollectionsPage />} />
        <Route path={routes.collection(":userId", ":id")} element={<CollectionItemsPage />} />
        <Route path={routes.following()} element={<WithAuthRequired><FollowingPage userName={currentUserName} /></WithAuthRequired>} />
        <Route path={routes.followers()} element={<WithAuthRequired><FollowersPage userName={currentUserName}/></WithAuthRequired>} />
        <Route path={routes.singleTag(":tag")} element={<TagPage />} />
        <Route path={routes.search()} element={<SearchPage />} />
        <Route path={routes.freetypeSearch()} element={<FreetypeSearchPage />} />
        <Route path={routes.trendingTags()} element={<CategoriesPage />} />
        <Route path={routes.trendingTagsA(":letter")} element={<CategoriesPage />} />
        <Route path={routes.create()} element={<UploadPage />} />
        <Route path={routes.manageProfile()} element={<WithAuthRequired><ManagePage /></WithAuthRequired>} />
        <Route path={routes.accountSettings()} element={<WithAuthRequired><AccountSettingsPage /></WithAuthRequired>} />
        <Route path={routes.profileSettings()} element={<WithAuthRequired><ProfileSettingsPage /></WithAuthRequired>} />
        <Route path={routes.verify()} element={<WithAuthRequired><VerifyPage /></WithAuthRequired>} />
        <Route path={routes.unsubscribed()} element={<UnsubscribedPage />} />
        <Route path={routes.authorize()} element={<WithAuthRequired><AuthorizePage /></WithAuthRequired>} />
        <Route path={routes.experiments()} element={<ExperimentsPage />} />
        <Route path={routes.sudo()} element={<WithAuthRequired><SudoPage /></WithAuthRequired>} />
        <Route path={routes.manageAdSlots()} element={<WithAuthRequired><ManageAdSlotsPage /></WithAuthRequired>} />

        <Route path={routes.guidelines()} element={<GuidelinesPage />} />
        <Route path={routes.faq()} element={<FAQPage />} />
        <Route path={routes.terms()} element={<TermsPage />} />
        <Route path={routes.statement2257()} element={<Statement2257Page />} />
        <Route path={routes.dmca()} element={<DMCAPage />} />
        <Route path={routes.privacyPolicy()} element={<PrivacyPolicyPage />} />
        <Route path={routes.texasSlatePage()} element={<TexasSlatePage />} />
        <Route path={routes.searchWarningPage()} element={<SearchWarningPage />} />

        <Route path={routes.userSignIn()} element={<SignInPage />} />
        <Route path={routes.userSignUp()} element={<SignUpPage />} />

        <Route path="*" element={<Navigate to={routes.home()} />} />
      </Routes>
    </Suspense>
  );
};

export default RoutesComponents;
