import React, { Suspense } from "react";
import "components/styles/variables.css";
import "./App.scss";
import "services/localMetrics/handlers";
import "services/amplitude/handlers";
import "services/ga/handlers";
import "services/recombee/handlers";
import "services/microsoftClarity/handlers";
import { BrowserRouter } from "react-router-dom";
import { ApplicationFooter } from "components/footer/ApplicationFooter";
import { ApplicationHeader } from "components/header/ApplicationHeader";
import { AppHooks } from "components/app/AppHooks";
import { AppContent } from "components/app/AppContent";
import GoCtrl from "components/ads/goCtrl";
import { className } from "utils/strings";
import { setAppHeight } from "utils/hacks";
import { PopupDispatcher } from "components/popups/PopupDispatcher";
import { ResponseMessage } from "components/UI/ResponseMessage";
import { checkIncognito } from "utils/detectIncognito";

window.addEventListener("resize", setAppHeight);
setAppHeight();
checkIncognito();

export const App = () => {
  return (
    <div className={className({
      "App": true,
      "nav": true,
    })}>
      <BrowserRouter>

        <ApplicationHeader />

        <Suspense><AppContent /></Suspense>

        <Suspense><ApplicationFooter /></Suspense>

        <PopupDispatcher />
        <GoCtrl />
        <AppHooks />
      </BrowserRouter>

      <Suspense><ResponseMessage /></Suspense>
    </div>
  );
};
