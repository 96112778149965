import { useEffect } from "react";
import { useUserStore } from "store/userStore";
import { bus } from "buses/metrics/bus";
import { useDeviceType } from "hooks";
import sessionService from "services/session/sessionService";
import microsoftClarity from "services/microsoftClarity/microsoftClarity";

export const useTrackUser = () => {
  const user = useUserStore((state) => state.currentUser);
  const setSessionId = useUserStore((state) => state.setSessionId);
  const deviceType = useDeviceType();

  useEffect(() => {
    (async () => {
      const sessionId = (await sessionService.getSession()).session;
      setSessionId(sessionId);
      microsoftClarity.startTracking();
      bus.emit("deviceTracked", { deviceId: sessionId, deviceType });
    })();
  }, []);

  useEffect(() => {
    if (user?.username) {
      bus.emit("userFetched", { user });
    }
  }, [user]);
};
