export const className = (props: {
  [key: string]: boolean;
}): string => {
  const parts = [];

  for (const key in props) {
    if (key && props[key]) {
      parts.push(key);
    }
  }

  if (parts.length === 0) {
    return "";
  }

  return parts.join(" ");
};

export const encodeTag = (tag: string): string => {
  if (!tag) {
    return "undefined";
  }

  // A special case for the route map.
  if (tag[0] === ":") {
    return tag;
  }

  return tag.toString().trim().replace(/[^a-z\d]+/gi, "-").toLowerCase();
};

export const encodeTags = (tags: string[]): string => {
  return tags.map(item => encodeTag(item)).join(",");
};

export const decodeTag = (encoded: string): string => {
  const parts = encoded.split("-");

  const words = parts.map(word => word.charAt(0).toUpperCase() + word.slice(1));

  return words.join(" ");
};

export const decodeTags = (encoded: string): string[] => {
  return encoded.split(",").map(tag => decodeTag(tag));
};

export const relToContext = (value: string | null | undefined): string | null => {
  if (!value) {
    return null;
  }

  return value.split(";")[0].replace(/,([agi])$/, "");
};
