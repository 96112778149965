import { Video } from "components/UI/Video";
import { usePortableVideo } from "./hooks";

export const PortableVideo = () => {
  const { videoRef, videoAttributes, videoShown, onMounted } = usePortableVideo();

  return (
    <div style={{ display: "none" }}>
      {videoShown && <Video videoRef={videoRef} {...videoAttributes} onMounted={onMounted} />}
    </div>
  );
};
