import { useCallback, useEffect } from "react";
import { useAppStore } from "store/appStore";

export const useHideAllMenus = () => {
  const hideAllMenus = useAppStore((state) => state.hideAllMenus);

  const handleBindedKey = useCallback((event: KeyboardEvent) => {
    if (event.code === "Escape") {
      hideAllMenus();
    }
  }, [hideAllMenus]);

  useEffect(() => {
    document.addEventListener("keydown", handleBindedKey);

    return () => {
      document.removeEventListener("keydown", handleBindedKey);
    };
  }, [handleBindedKey]);
};
