import { createRef, RefObject, VideoHTMLAttributes } from "react";
import { create } from "zustand";

interface IPortableVideoState {
  videoRef: React.RefObject<HTMLVideoElement>;
  videoAttributes: VideoHTMLAttributes<HTMLVideoElement>;
  targetRef: React.RefObject<HTMLElement>;
  updateVideo: (value: VideoHTMLAttributes<HTMLVideoElement>) => void;
  portVideo: (value: RefObject<HTMLElement>) => void;
}

export const usePortableVideoStore = create<IPortableVideoState>((set) => ({
  videoRef: createRef<HTMLVideoElement>(),
  targetRef: createRef<HTMLElement>(),
  videoAttributes: {},
  updateVideo: (value) => set((state) => ({ videoAttributes: value })),
  portVideo: (value) => set(() => ({ targetRef: value })),
}));
