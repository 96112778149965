import { detectIncognito } from "detectincognitojs";
import { metricsBus } from "buses";

export const checkIncognito = async() => {
  if (await isPrivate()) {
    metricsBus.emit("privateSessionStarted");
  }
};

export const isPrivate = async () => {
  try {
    const { isPrivate } = await detectIncognito();
    return isPrivate;
  } catch (e) {
    return false;
  }
};
