import { apiMain } from "services";
import { GifPlaybackEvent, GifViewEvent } from "buses";

class LocalMetrics {
  async sendGifView(props: GifViewEvent): Promise<void> {
    try {
      await apiMain.sendGifMetrics({
        event: "view",
        gif: props.gifId,
        position: props.position,
        source: props.source,
        boosted: props.boosted,
        username: props.userName,
        fullscreen: props.fullscreen,
      });
    } catch (err) {
      console.error("Error sending metrics.");
    }
  }

  async sendGifPlayback(props: GifPlaybackEvent): Promise<void> {
    try {
      await apiMain.sendGifMetrics({
        event: "playback",
        username: props.userName,
        duration: props.duration, // in ms
        gif: props.gif,
        source: props.context,
        boosted: props.boosted,
      });
    } catch (err) {
      console.error("Error sending metrics.");
    }
  }
}

export default new LocalMetrics();
