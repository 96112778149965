import { lazy } from "react";

export const AccountSettingsPage = lazy(() => import("pages/AccountSettingsPage/AccountSettingsPage"));
export const AnalyticsPage = lazy(() => import("pages/AnalyticsPage/AnalyticsPage"));
export const AuthorizePage = lazy(() => import("pages/AuthorizePage/AuthorizePage"));
export const CategoriesPage = lazy(() => import("pages/CategoriesPage/CategoriesPage"));
export const CollectionItemsPage = lazy(() => import("pages/CollectionItemsPage/CollectionItemsPage"));
export const CollectionsPage = lazy(() => import("pages/CollectionsPage/CollectionsPage"));
export const CrashPage = lazy(() => import("pages/CrashPage/CrashPage"));
export const DMCAPage = lazy(() => import("pages/staticPages/DMCAPage"));
export const EmbedPage = lazy(() => import("pages/EmbedPage/EmbedPage"));
export const ExperimentsPage = lazy(() => import("pages/ExperimentsPage/ExperimentsPage"));
export const ExploreCreatorsPage = lazy(() => import("pages/ExploreCreatorsPage/ExploreCreatorsPage"));
export const ExploreGifsPage = lazy(() => import("pages/ExploreGifsPage/ExploreGifsPage"));
export const ExploreImagesPage = lazy(() => import("pages/ExploreImagesPage/ExploreImagesPage"));
export const ExploreNichesPage = lazy(() => import("pages/ExploreNichesPage/ExploreNichesPage"));
export const ExplorePage = lazy(() => import("pages/ExplorePage/ExplorePage"));
export const FAQPage = lazy(() => import("pages/staticPages/FAQPage"));
export const FavouritesPage = lazy(() => import("pages/FavouritesPage/FavouritesPage"));
export const FollowersPage = lazy(() => import("pages/subscriptions/FollowersPage"));
export const FollowingPage = lazy(() => import("pages/subscriptions/FollowingPage"));
export const GuidelinesPage = lazy(() => import("pages/staticPages/GuidelinesPage"));
export const HomePage = lazy(() => import("pages/HomePage/HomePage"));
export const ManageAdSlotsPage = lazy(() => import("pages/ManageAdSlotsPage/ManageAdSlotsPage"));
export const ManagePage = lazy(() => import("pages/ManagePage"));
export const MigrateImgurConfirmPage = lazy(() => import("pages/Migrate/MigrateImgurConfirmPage"));
export const MigrateImgurPage = lazy(() => import("pages/Migrate/MigrateImgurPage"));
export const MigrateImgurSuccessPage = lazy(() => import("pages/Migrate/MigrateImgurSuccessPage"));
export const MyNichesPage = lazy(() => import("pages/MyNichesPage/MyNichesPage"));
export const NichePage = lazy(() => import("pages/NichePage/NichePageContainer"));
export const PrivacyPolicyPage = lazy(() => import("pages/staticPages/PrivacyPolicyPage"));
export const ProfileSettingsPage = lazy(() => import("pages/ProfileSettingsPage/ProfileSettingsPage"));
export const SearchPage = lazy(() => import("pages/SearchPage/SearchPage"));
export const FreetypeSearchPage = lazy(() => import("pages/FreetypeSearchPage/FreetypeSearchPage"));
export const Statement2257Page = lazy(() => import("pages/staticPages/Statement2257Page"));
export const SudoPage = lazy(() => import("pages/SudoPage/SudoPage"));
export const TagPage = lazy(() => import("pages/TagPage/TagPage"));
export const TermsPage = lazy(() => import("pages/staticPages/TermsPage"));
export const TexasSlatePage = lazy(() => import("pages/TexasSlate/TexasSlatePage"));
export const UnsubscribedPage = lazy(() => import("pages/UnsubscribedPage/UnsubscribedPage"));
export const UploadPage = lazy(() => import("pages/UploadPage/UploadPage"));
export const UserPage = lazy(() => import("pages/UserPage/UserPage"));
export const VerifyPage = lazy(() => import("pages/VerifyPage/VerifyPage"));
export const WatchPage = lazy(() => import("pages/WatchPage/WatchPage"));
export const SearchWarningPage = lazy(() => import("pages/BannedWordSearchWarning/SearchWarningPage"));
export const SignInPage = lazy(() => import("pages/SignInPage/SignInPage"));
export const SignUpPage = lazy(() => import("pages/SignUpPage/SignUpPage"));
