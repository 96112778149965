export function nFormatter(num: number, decimalPlaces: number = 0) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(decimalPlaces).replace(rx, "$1") + item.symbol : "0";
}

export function formatDuration(duration: number) {
  const seconds = Math.round(duration);

  /* eslint-disable no-magic-numbers */

  if (seconds < 10) {
    return `0:0${seconds}`;
  }

  if (seconds < 60) {
    return `0:${seconds}`;
  }

  return "1:00";
}
