import { useCallback, useEffect, useState } from "react";
import { deviceTypeEnum, getDeviceType, getWindowWidth } from "utils/device";

export const useDeviceType = (): deviceTypeEnum => {
  const [deviceType, setDeviceType] = useState<deviceTypeEnum>(getDeviceType(getWindowWidth()));

  const handleResize = useCallback(() => {
    setDeviceType(getDeviceType(getWindowWidth()));
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return deviceType;
};
