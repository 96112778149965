import { clsx } from "clsx";
import { ReactNode } from "react";
import "./PinkBaseButton.scss";

interface IPinkBaseButtonProps extends React.ComponentPropsWithRef<"button"> {
  onClick?: () => void;
  isSelected?: boolean;
  variant?: string;
  children: ReactNode;
}

export const PRIMARY = "primary";
export const SECONDARY = "secondary";

export const PinkBaseButton = ({
  variant = PRIMARY,
  onClick = undefined,
  isSelected,
  children,
  className,
  ...restProps
}: IPinkBaseButtonProps) => {
  const classes = clsx([
    "PinkBaseButton",
    className,
    {
      "PinkBaseButton_isPrimary": variant === PRIMARY,
      "PinkBaseButton_isSecondary": variant === SECONDARY,
      "PinkBaseButton_isSelected": isSelected,
    },
  ]);

  return (
    <button className={classes} onClick={onClick} {...restProps}>
      { children }
    </button>
  );
};
