import { Method, AxiosRequestHeaders, AxiosError } from "axios";
import { getAccessToken, isAuthenticated, logout } from "./Auth";
import sessionService from "services/session/sessionService";
import { BaseHttpService, ErrorResponseData } from "./BaseHttpService";

const RETRY_LIMIT = 3;

const UNAUTHORIZED_CODE = 401;

export default abstract class AuthHttpService extends BaseHttpService {

  protected constructor (readonly url?: string) {
    super(url);
  }

  protected async request<Response,Params>(
    method: Method,
    url: string,
    data?: Params,
    headers?: AxiosRequestHeaders,
    retry?: number
  ): Promise<Response> {
    const authenticated = await isAuthenticated();
    const token: string = await getAccessToken();

    const requestHeaders: AxiosRequestHeaders = headers || {};
    requestHeaders["Authorization"] = `Bearer ${token}`;

    return super.request<Response,Params>(method, url, data, requestHeaders)
      .catch(async (reason: AxiosError<ErrorResponseData | undefined>) => {
        const requestRetry = retry || 1;
        const { response } = reason;

        const code = response?.data?.error?.code || "";
        const status = response?.status || 0;

        if (status === UNAUTHORIZED_CODE) {
          if (authenticated) {
            logout();
          } else if (requestRetry < RETRY_LIMIT) {
            console.debug(`Request failed with code=${code}, getting new guest token, retry=${retry}`);

            await sessionService.refreshSession();
            return this.request<Response,Params>(method, url, data, headers, requestRetry + 1);
          } else {
            console.debug(`Request to ${url} failed with code=${code}, not getting new guest token, retry=${retry}.`);
          }
        }

        return Promise.reject(reason);
      });
  }
}
