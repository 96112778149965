import CollectionService from "./CollectionService";
import CreatorsService from "./CreatorsService";
import NichesService from "./NichesService";
import RedGifsService from "./RedGifsService";
import SearchService from "./SearchService";
import StripChatService from "./StripChatService";
import TagsService from "./TagsService";
import UploadService from "./UploadService";
import VisitorService from "./VisitorService";
import AuthService from "./AuthService";

export const apiCollections = new CollectionService();
export const apiCreators = new CreatorsService();
export const apiMain = new RedGifsService();
export const apiNiches = new NichesService();
export const apiSearch = new SearchService();
export const apiStripChat = new StripChatService();
export const apiTags = new TagsService();
export const apiUpload = new UploadService();
export const apiVisitor = new VisitorService();
export const apiAuth = new AuthService();
