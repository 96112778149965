import { useEffect } from "react";

export const useWindowHistoryByMouse = () => {
  useEffect(() => {
    const handleMouseUp = (e: MouseEvent) => {
      if (e.button === 3) {
        window.history.back();
      }

      if (e.button === 4) {
        window.history.forward();
      }
    };

    document.addEventListener("mouseup", handleMouseUp);

    return () => document.removeEventListener("mouseup", handleMouseUp);
  }, []);
};
