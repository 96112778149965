import { useEffect } from "react";
import { initNativeAd, initTsMasterSpot, resetAdn } from "./failovers";
import { useLocation } from "react-router-dom";
import { isDisableAds } from "./utils";
import { metricsBus } from "buses";
import { useExperiment } from "hooks/useExperiment";
import { EXPERIMENT_NO_ADS } from "services/experiment/constants";
import { IAdUnit } from "components/ads/config/unit";

declare global {
  interface Window {
    goCtrl: {
      ctrlId: number;
      debugOn: boolean;
      bypassAb: boolean;
      lazyLoading: boolean;
      connectors: {
        [key: string]: {
          isproxy: number;
          type: string;
          url: string;
        };
      };
      init: () => void;
      add: (props: IAdUnit) => void;
    };
  }
}

const TIMEOUT = 1000;

const loadScript = ({ cb } : { cb: () => void}) => {
  try {
    if (
      typeof window.goCtrl === "undefined" &&
      window.location.pathname.indexOf("/ifr/") !== 0
    ) {
      const js = document.createElement("script");

      js.async = true;
      js.onload = cb;

      js.onerror = e => {
        metricsBus.emit("adLoaded", { isLoaded: false });
        console.debug("[ads] eaCtrl not found -- ads blocked?", e);
      };

      const timestamp = new Date().getTime();
      js.src = `/assets/js/goCtrl-v2.js?v=${timestamp}`;

      document.head.appendChild(js);
    }
  } catch (e) {
    metricsBus.emit("adLoaded", { isLoaded: false });
    console.debug("[ads] eaCtrl not found -- ads blocked?", e);
  }
};

const init = () => {
  initTsMasterSpot();
  initNativeAd();

  loadScript({
    cb: () => {
      console.debug("[ads] initializing goCtrl");

      try {
        window.goCtrl.ctrlId = 112; // the id of this controller
        window.goCtrl.debugOn = true; // set on true in case you want to debug, eaCtrl will log errors or other info in the console
        window.goCtrl.bypassAb = false; // change to enable/disable
        window.goCtrl.lazyLoading = false; // change to enable/disable lazyLoading, default it only display an ad when it reach the fold
        window.goCtrl.connectors = {
          t1: { isproxy: 0, type: "url", url: "//go.redgifs.com/eactrl.go" }
        };

        window.goCtrl.init();
        metricsBus.emit("adLoaded", { isLoaded: true });
      } catch (e) {
        metricsBus.emit("adLoaded", { isLoaded: false });
        console.error(`[ads] error initializing goCtrl: ${e}`);
      }
    }
  });
};

const GoCtrl = () => {
  const location = useLocation();
  const { variant: noAds, isLoading } = useExperiment({ key: EXPERIMENT_NO_ADS, fallback: false });

  useEffect(resetAdn, [location]);

  useEffect(() => {
    if (isDisableAds()) {
      return;
    }

    if (noAds || isLoading) {
      return;
    }

    setTimeout(() => {
      init();
    }, TIMEOUT);
  }, [isLoading, noAds]);

  return null;
};

export default GoCtrl;
