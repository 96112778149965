import { Dispatch, SetStateAction } from "react";
import { ITEMS_TYPES } from "./constants";
import { routes } from "routes";
import { useNavigate } from "react-router-dom";
import { nFormatter } from "utils/parseNumber";
import { useQuery } from "hooks/useQuery";
import { IHistoryList, INichesItems, ICreatorsItems } from "services/types";
import { useAppStore } from "store/appStore";
import DefaultUserIcon from "components/icons/default_userpic.svg?react";
import { VerifiedIcon } from "../VerifiedIcon";

import "./styles/SearchResultItem.scss";

interface ISearchResultItemProps {
  itemType: string;
  itemData: IHistoryList | INichesItems | ICreatorsItems;
  setSearchInput: Dispatch<SetStateAction<string>>;
  setCurrentTag: Dispatch<SetStateAction<string[]>>;
}

export const SearchResultItem = ({ itemType, itemData, setSearchInput, setCurrentTag }: ISearchResultItemProps) => {
  const navigate = useNavigate();
  const query = useQuery();
  const hideAllMenus = useAppStore((state) => state.hideAllMenus);

  const isNicheItem = (item: IHistoryList | INichesItems | ICreatorsItems): item is INichesItems => {
    return (item as INichesItems).id !== undefined;
  };

  const isPersonItem = (item: IHistoryList | INichesItems | ICreatorsItems): item is ICreatorsItems => {
    return (item as ICreatorsItems).verified !== undefined;
  };

  const handleClickSearchItem = () => {
    setSearchInput("");

    if (isNicheItem(itemData)) {
      navigate(routes.niche(itemData.id));
    } else if (isPersonItem(itemData)) {
      navigate(routes.user(itemData.text.substr(1)));
    } else {
      setCurrentTag(prev => {
        if (prev.includes(itemData.text)) {
          navigate(routes.multipleTag(prev, query.get("order") ?? undefined, !!query.get("verified")));
          return prev;
        }

        if (prev.length >= 3) {
          return prev;
        }

        navigate(routes.multipleTag([...prev, itemData.text], query.get("order") ?? undefined, !!query.get("verified")));
        return [...prev, itemData.text];
      });

    }
    document?.getElementById("global-search-input")?.blur();
    hideAllMenus();
  };

  const renderIcon = () => {
    if (itemType === ITEMS_TYPES.isSavedItem) {
      return <div className="SearchResultItem-ClockIcon" />;
    }

    if (isPersonItem(itemData)) {
      return itemData.image ? (
        <div className="SearchResultItem-PersonIcon" style={{ backgroundImage: `url(${itemData.image})` }} />
      ) : (
        <div className="SearchResultItem-PersonIcon SearchResultItem-PersonIcon_isEmpty"><DefaultUserIcon /></div>
      );
    }

    if (isNicheItem(itemData)) {
      return itemData.image ? (
        <div className="SearchResultItem-NicheIcon" style={{ backgroundImage: `url(${itemData.image})` }} />
      ) : (
        <div className="SearchResultItem-NicheIcon SearchResultItem-NicheIcon_isEmpty"><DefaultUserIcon /></div>
      );
    }
  };

  const renderInfo = () => {
    const isPerson = isPersonItem(itemData);

    if (itemType === ITEMS_TYPES.isSavedItem || isPerson) {
      return (
        <div className="SearchResultItem-Cnt">
          <h4>
            {isPerson ? itemData.name : itemData.text}
            {isPerson && itemData.verified && <VerifiedIcon title={`Verified ${itemData.studio ? "studio" : "user"}`} isStudio={itemData.studio} />}
          </h4>
          {isPerson && <p>{itemData.text} | {nFormatter(itemData.followers)} <span>followers</span></p>}
        </div>
      );
    }

    if (isNicheItem(itemData)) {
      return (
        <div className="SearchResultItem-Cnt">
          <h4>{itemData.text}</h4>
          <p>Niche | {nFormatter(itemData.subscribers)} members</p>
        </div>
      );
    }
  };

  return (
    <li className="SearchResultItem" onClick={handleClickSearchItem}>
      { renderIcon() }
      { renderInfo() }
      <div className="SearchResultItem-Arrow"/>
    </li>
  );
};
