import { create } from "zustand";
import { IUser } from "services/types";

interface IUserState {
  // initial state
  sessionId: string | null;
  currentUser: IUser;
  loadingCurrentUser: boolean;

  // actions
  setCurrentUser: (currentUser: IUser) => void;
  setLoadingCurrentUser: (loadingCurrentUser: boolean) => void;
  setSessionId: (sessionId: string) => void;
}

export const useUserStore = create<IUserState>(
  (set) => ({
    // initial state
    sessionId: null,
    currentUser: {} as IUser,
    loadingCurrentUser: false,

    // actions
    setCurrentUser: (currentUser: IUser) => set(() => ({ currentUser })),
    setLoadingCurrentUser: (loadingCurrentUser: boolean) => set(() => ({ loadingCurrentUser })),
    setSessionId: (sessionId: string) => set(() => ({ sessionId })),
  }),
);
