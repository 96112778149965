import React, { useEffect, useState } from "react";

interface IWithTimer {
  children: React.ReactNode;
  waitTime: number;
}

export const WithTimer = ({ waitTime, children }: IWithTimer) => {
  const [timerFinished, setTimerFinished] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimerFinished(true);
    }, waitTime);

    return () => clearTimeout(timer);
  }, [waitTime]);

  return timerFinished ? <>{children}</> : <></>;
};
