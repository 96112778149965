import { metricsBus } from "buses";
import amplitudeAnalytics from "services/amplitude/amplitudeAnalytics";
import amplitudeExperiments from "services/amplitude/amplitudeExperiments";
import seedrandom from "seedrandom";

const isFullscreen = () => {
  return !!document.querySelector("#root")?.classList?.contains("fullScreen");
};

const isWatchPage = () => window.location.pathname.startsWith("/watch/");
const isHomePage = () => window.location.pathname === "/";

metricsBus.on("forceFullscreenEntered",() => amplitudeAnalytics.sendForcedFullscreenEntered());

metricsBus.on("forceFullscreenExited", () => amplitudeAnalytics.sendForcedFullscreenExitClicked());

metricsBus.on("uploadStarted", () => amplitudeAnalytics.sendUploadStarted());

metricsBus.on("uploadTagSelectionStarted", () => amplitudeAnalytics.sendUploadTagSelectionStarted());

metricsBus.on("uploadTagSelectionEnded", ({ count }) => amplitudeAnalytics.sendUploadTagSelectionEnded(count));

metricsBus.on("uploadEnded", () => amplitudeAnalytics.sendUploadEnded());

metricsBus.on("watchPageVisited", () => amplitudeAnalytics.sendWatchPageVisited());

metricsBus.on("gifView", ({ gifId }) => {
  if (isWatchPage() || isHomePage()) {
    return amplitudeAnalytics.sendGifViewed(gifId, isFullscreen());
  }
});

metricsBus.on("meaningfulGifView", ({ gifId }) => {
  if (isWatchPage() || isHomePage()) {
    return amplitudeAnalytics.sendGifViewedMeaningful(gifId, isFullscreen());
  }
});

metricsBus.on("gifLiked", ({ gifId, isMainGIF }) => {
  if (isWatchPage() || isHomePage()) {
    return amplitudeAnalytics.sendGifLiked(gifId, isFullscreen(), isWatchPage() ? isMainGIF : undefined);
  }
});

metricsBus.on("gifUnliked", ({ gifId, isMainGIF }) => {
  if (isWatchPage() || isHomePage()) {
    return amplitudeAnalytics.sendGifUnliked(gifId, isFullscreen(), isWatchPage() ? isMainGIF : undefined);
  }
});

metricsBus.on("addedToCollection", ({ gifId }) => {
  if (isWatchPage() || isHomePage()) {
    return amplitudeAnalytics.sendGifAddedToCollection(gifId, isFullscreen());
  }
});

metricsBus.on("questionnaireDisplayed", ({ title, step }) => amplitudeAnalytics.sendQuestionnaireDisplayed(title, step));

metricsBus.on("questionnaireCompleted", () => amplitudeAnalytics.sendQuestionnaireCompleted());

metricsBus.on("questionnaireClosed", ({ title, step }) => amplitudeAnalytics.sendQuestionnaireClosed(title, step));

metricsBus.on("preferenceSubmitted", ({ title, step, preference }) => amplitudeAnalytics.sendPreferenceSubmitted(title, step, preference));

metricsBus.on("fullscreenEntered", () => amplitudeAnalytics.sendFullscreenEntered());

metricsBus.on("fullscreenExited", () => amplitudeAnalytics.sendFullscreenExited());

metricsBus.on("menuButtonClicked", ({ label }) => amplitudeAnalytics.sendNavButtonClicked(label));

metricsBus.on("searchBarOpened", () => amplitudeAnalytics.sendSearchBarClicked());

metricsBus.on("searched", ({ query }) => amplitudeAnalytics.sendSearchQuerySubmitted(query));

metricsBus.on("loginCompleted", () => amplitudeAnalytics.sendLoginCompleted());

metricsBus.on("userFetched", ({ user }) => amplitudeAnalytics.identifyUser(user));

metricsBus.on("homePageVisited", () => amplitudeAnalytics.sendHomePageVisited());

metricsBus.on("deviceTracked", ({ deviceId, deviceType }) => {
  const getDeviceScore = seedrandom(deviceId);

  amplitudeAnalytics.setDeviceId(deviceId);
  amplitudeAnalytics.updateUserProperties({
    "Device Score": getDeviceScore(),
    "Device Type": deviceType,
  });
});

metricsBus.on("experimentVariantExposure", ({ experimentKey, experimentVariant }) => {
  amplitudeExperiments.exposure(experimentKey);
  amplitudeAnalytics.updateUserProperties({ [experimentKey]: experimentVariant });
});

metricsBus.on("firstPageVisited", ({ path }) => {
  if (path.startsWith("/watch/")) {
    amplitudeAnalytics.updateUserProperties({
      "Landed to Watch Page": true
    });
  }
});

