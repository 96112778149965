import { bus } from "./bus";
import { PopupsEnum } from "components/popups/PopupDispatcher/popups";
import { IAddToNichePopupProps } from "components/niches/AddToNichePopup";
import { IReportPopupProps } from "components/UI/Popups/Report";
import { IAddToCollectionPopupProps } from "components/UI/Popups/Collections";
import { IEditNichePopupContainerProps } from "components/popups/EditNichePopup";
import { IEditNichesDialogProps } from "components/dialogs/EditNichesDialog";
import { IGifPreferencePopupProps } from "components/UI/Popups/GifPreferencePopup";
import { IShareGifMenuProps } from "components/UI/ShareMenu";
import { IMoreMenuContainerProps } from "components/popups/MoreMenu";
import { INicheMoreMenuContainerProps } from "components/popups/NicheMoreMenu";
import { IEditGifPopupProps } from "components/popups/EditGifPopup";
import { IAddDescriptionPopup } from "components/popups/AddDescriptionPopup";
import { IAddToNicheConfirm } from "components/popups/AddToNicheConfirm";

export const onOpenPreferencesPopup = () => {
  bus.emit("open", { name: PopupsEnum.Preferences });
};

export const onClosePreferencesPopup = () => {
  bus.emit("close", { name: PopupsEnum.Preferences });
};

export const onOpenChangedEmailPopup = () => {
  bus.emit("open", { name: PopupsEnum.ChangedEmail });
};

export const onCloseChangedEmailPopup = () => {
  bus.emit("close", { name: PopupsEnum.ChangedEmail });
};

export const onOpenAddNichePopup = (props: IAddToNichePopupProps) => {
  bus.emit("open", { name: PopupsEnum.AddNiche, ...props });
};

export const onCloseAddNichePopup = () => {
  bus.emit("close", { name: PopupsEnum.AddNiche });
};

export const onOpenReportPopup = (props: IReportPopupProps) => {
  bus.emit("open", { name: PopupsEnum.Report, ...props });
};

export const onCloseReportPopup = () => {
  bus.emit("close", { name: PopupsEnum.Report });
};

export const onOpenAddCollectionPopup = (props: IAddToCollectionPopupProps) => {
  bus.emit("open", { name: PopupsEnum.AddCollection, ...props });
};

export const onCloseAddCollectionPopup = () => {
  bus.emit("close", { name: PopupsEnum.AddCollection });
};

export const onOpenEditNichePopup = (props: IEditNichePopupContainerProps) => {
  bus.emit("open", { name: PopupsEnum.EditNiche, ...props });
};

export const onCloseEditNichePopup = () => {
  bus.emit("close", { name: PopupsEnum.EditNiche });
};

export const onOpenEditGifNichesPopup = (props: IEditNichesDialogProps) => {
  bus.emit("open", { name: PopupsEnum.EditGifNiches, ...props });
};

export const onCloseEditGifNichesPopup = () => {
  bus.emit("close", { name: PopupsEnum.EditGifNiches });
};

export const onOpenEditGifPreferencesPopup = (props: IGifPreferencePopupProps) => {
  bus.emit("open", { name: PopupsEnum.EditGifPreferences, ...props });
};

export const onCloseEditGifPreferencesPopup = () => {
  bus.emit("close", { name: PopupsEnum.EditGifPreferences });
};

export const onOpenShareGifMenu = (props: IShareGifMenuProps) => {
  bus.emit("open", { name: PopupsEnum.ShareGif, ...props });
};

export const onCloseShareGifMenu = () => {
  bus.emit("close", { name: PopupsEnum.ShareGif });
};

export const onOpenMoreMenu = (props: IMoreMenuContainerProps) => {
  bus.emit("open", { name: PopupsEnum.MoreMenu, ...props });
};

export const onCloseMoreMenu = () => {
  bus.emit("close", { name: PopupsEnum.MoreMenu });
};

export const onOpenNicheMoreMenu = (props: INicheMoreMenuContainerProps) => {
  bus.emit("open", { name: PopupsEnum.NicheMoreMenu, ...props });
};

export const onCloseNicheMoreMenu = () => {
  bus.emit("close", { name: PopupsEnum.NicheMoreMenu });
};

export const onOpenCookieNotice = () => {
  bus.emit("open", { name: PopupsEnum.CookieNotice });
};

export const onCloseCookieNotice = () => {
  bus.emit("close", { name: PopupsEnum.CookieNotice });
};

export const onOpenEditGifPopup = (props: IEditGifPopupProps) => {
  bus.emit("open", { name: PopupsEnum.EditGif, ...props });
};

export const onCloseEditGifPopup = () => {
  bus.emit("close", { name: PopupsEnum.EditGif });
};

export const onOpenAddToNicheSuccessPopup = () => {
  bus.emit("open", { name: PopupsEnum.AddToNicheSuccess });
};

export const onCloseAddToNicheSuccessPopup = () => {
  bus.emit("close", { name: PopupsEnum.AddToNicheSuccess });
};

export const onOpenAddDescriptionPopup = (props: IAddDescriptionPopup) => {
  bus.emit("open", { name: PopupsEnum.AddDescriptionPopup, ...props });
};

export const onCloseAddDescriptionPopup = () => {
  bus.emit("close", { name: PopupsEnum.AddDescriptionPopup });
};

export const onOpenAddToNicheConfirm = (props: IAddToNicheConfirm) => {
  bus.emit("open", { name: PopupsEnum.AddToNicheConfirm, ...props });
};

export const onCloseAddToNicheConfirm = () => {
  bus.emit("close", { name: PopupsEnum.AddToNicheConfirm });
};

export const onOpenPassionStep = () => {
  bus.emit("open", { name: PopupsEnum.PassionStep });
};

export const onClosePassionStep = () => {
  bus.emit("close", { name: PopupsEnum.PassionStep });
};

export const onOpenSceneStep = () => {
  bus.emit("open", { name: PopupsEnum.SceneStep });
};

export const onCloseSceneStep = () => {
  bus.emit("close", { name: PopupsEnum.SceneStep });
};

export const onOpenTagsStep = () => {
  bus.emit("open", { name: PopupsEnum.TagsStep });
};

export const onCloseTagsStep = () => {
  bus.emit("close", { name: PopupsEnum.TagsStep });
};
