import classes from "./AdTab.module.scss";
import { AdTabProps } from "./config/tab";
import { memo, PropsWithChildren, useCallback } from "react";
import { useExperiment } from "hooks/useExperiment";
import { EXPERIMENT_NO_ADS } from "services/experiment/constants";

const AdTab = ({ link, children }: AdTabProps & PropsWithChildren) => {
  const { isLoading, variant: noAds } = useExperiment({ key: EXPERIMENT_NO_ADS, fallback: false });

  const handleClick = useCallback(() => {
    window.location.href = link;
  }, [link]);

  if (noAds || isLoading) {
    return null;
  }

  return (
    <span className={classes.aTab} onClick={handleClick}>{children}</span>
  );
};

const adTabMemo = memo(AdTab);

export default adTabMemo;
