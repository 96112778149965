import { useEffect } from "react";
import { onOpenChangedEmailPopup } from "buses";

export const useCheckExternalMessages = () => {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const value = searchParams.get("message");

    if (value) {
      onOpenChangedEmailPopup();
    }
  }, []);
};
