import { loadSession, setSession } from "utils/storage";

import { ISessionInfo } from "services/types";
import { apiAuth } from "services";

class SessionService {
  private requestPromise: Promise<ISessionInfo> | null = null;
  private session = loadSession<ISessionInfo>();

  async getSession(): Promise<ISessionInfo> {
    if (this.session === null) {
      return await this.fetchSession();
    }

    return this.session;
  }

  async refreshSession(): Promise<ISessionInfo> {
    return await this.fetchSession();
  }

  private async fetchSession(): Promise<ISessionInfo> {
    const sessionId = this.session?.session;
    this.requestPromise = this.requestPromise ?? apiAuth.getSession(sessionId);
    this.session = await this.requestPromise.finally(() => {
      this.requestPromise = null;
    });

    setSession(this.session);

    return this.session;
  }
}

export default new SessionService();
