/* eslint-disable import/no-unused-modules */
import { Experiment } from "@amplitude/experiment-js-client";

const isProduction = import.meta.env.MODE === "production";
const API_KEY = import.meta.env.REACT_APP_AMPLITUDE_KEY;

const experiment = Experiment.initializeWithAmplitudeAnalytics(API_KEY, {
  debug: !isProduction,
  fetchOnStart: false,
  pollOnStart: false,
  fetchTimeoutMillis: 200,
  retryFetchOnFailure: false
});

export default experiment;
