import { Fragment, PropsWithChildren, useEffect, useState } from "react";
import { isAuthenticated } from "services/Auth";
import { useAuthNavigate } from "hooks/useAuthNavigate";

const WithAuthRequired = ({ children }: PropsWithChildren) => {
  const [isAuth, setIsAuth] = useState(false);
  const { navigateSignIn } = useAuthNavigate();

  useEffect(() => {
    (async () => {
      (await isAuthenticated()) ? setIsAuth(true) : navigateSignIn();
    })();
  }, []);

  return isAuth ? <Fragment>{children}</Fragment> : null;
};

export default WithAuthRequired;
