import classes from "./BurgerButton.module.scss";

import BurgerIcon from "components/icons/burgerIcon.svg?react";

interface IProps {
  onClick: () => void;
}

export const BurgerButton = ({ onClick }: IProps) => {
  return (
    <button type="button" className={classes.burgerButton} onClick={onClick} aria-label="Open the menu">
      <BurgerIcon/>
    </button>
  );
};
