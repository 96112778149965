import { useEffect } from "react";
import { useMeQuery } from "queries";
import { useUserStore } from "store/userStore";

export const useGetUserData = () => {
  const setCurrentUser = useUserStore((state) => state.setCurrentUser);
  const setLoadingCurrentUser = useUserStore((state) => state.setLoadingCurrentUser);
  const { data, isLoading } = useMeQuery();

  useEffect(() => {
    if (data) {
      setCurrentUser(data);
    }
    setLoadingCurrentUser(isLoading);
  }, [data, isLoading, setCurrentUser, setLoadingCurrentUser]);
};
