const MAX_THREAD_COUNT = 3;

class UrlPrefetcher {
  private urlQueue: string[] = [];
  private activeThreadCount: number = 0;

  public addUrl(url: string) {
    if (!this.urlQueue.includes(url)) {
      this.urlQueue.push(url);
    }
  }

  public run(urls: string[]) {
    urls.forEach(url => {
      this.addUrl(url);
    });

    this.prefetchUrls();
  }

  private prefetchUrls() {
    if (!this.urlQueue.length || this.activeThreadCount >= MAX_THREAD_COUNT) {
      return;
    }

    const urls = this.urlQueue.splice(0, MAX_THREAD_COUNT - this.activeThreadCount);
    if (!urls.length) {
      return;
    }

    urls.forEach(url => {
      this.activeThreadCount++;

      this.prefetchUrl(url).catch(() => null)
        .finally(() => {
          this.activeThreadCount--;
          this.prefetchUrls();
        });
    });
  }

  private async prefetchUrl(url: string): Promise<Response | void> {
    return await fetch(url, { method: "HEAD" });
  }
}

export default new UrlPrefetcher();

