declare global {
  interface Window {
    TsMasterSpot: (data: never, retry?: number) => void;
    NativeAd: (data: never, retry?: number) => void;
    adnLoaded: string[];
  }
}

const RETRY_LIMIT = 5;
const TIMEOUT = 1000;

export const initTsMasterSpot = () => {
  window.TsMasterSpot = (data, retry) => {
    const retryValue = retry || 1;

    if (retryValue > RETRY_LIMIT) {
      return;
    }

    setTimeout(() => {
      console.debug("[ads] TsMasterSpot didn't load. Waiting", retryValue);
      // eslint-disable-next-line new-cap
      window.TsMasterSpot(data, retryValue + 1);
    }, TIMEOUT);
  };
};

export const initNativeAd = () => {
  window.NativeAd = (data, retry) => {
    const retryValue = retry || 1;

    if (retryValue > RETRY_LIMIT) {
      return;
    }

    setTimeout(() => {
      console.debug("[ads] NativeAd didn't load. Waiting", retryValue);
      // eslint-disable-next-line new-cap
      window.NativeAd(data, retryValue + 1);
    }, TIMEOUT);
  };
};

export const resetAdn = () => {
  window.adnLoaded = [];
};
