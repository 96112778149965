import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

Error.stackTraceLimit = 1000;

const IGNORE_ERRORS = [
  "AbortError",
  "ChunkLoadError",
  "NotAllowedError",
  "NotSupportedError",
  "SecurityError",
];

const IGNORE_SUBSTRINGS = [
  "Can't find variable: IntersectionObserver",
  "Cannot read properties of null (reading 'appendChild')",
  "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
  "Failed to fetch dynamically imported module",
  "Failed to load https://cdn.jsdelivr.net/npm/",
  "Failed to read a named property 'dispatchEvent' from 'Window'",
  "Failed to read the 'localStorage' property from 'Window': Access is denied for this document.",
  "Importing a module script failed.",
  "Loading CSS chunk",
  "Minified React error",
  "Minified Redux error",
  "Object.entries is not a function",
  "Object.values is not a function",
  "SyntaxError: Unexpected end of script",
  "The operation is insecure.",
  "Unable to preload CSS",
  "Unexpected EOF",
  "Unexpected end of script",
  "can't access dead object",
  "console.debug is not a function",
  "console.error is not a function",
  "out of memory",
];

const hasIgnoredClass = (errorClass: string): boolean => {
  return IGNORE_ERRORS.includes(errorClass);
};

const hasIgnoredText = (errorMessage: string): boolean => {
  return !!IGNORE_SUBSTRINGS.find(substring => errorMessage.includes(substring));
};

Bugsnag.start({
  apiKey: import.meta.env.REACT_APP_BUGSNAG_API_KEY ?? "",
  appVersion: import.meta.env.REACT_APP_VERSION ?? "dev",
  releaseStage: import.meta.env.MODE,
  enabledReleaseStages: ["production", "staging"],
  autoTrackSessions: false,
  plugins: [new BugsnagPluginReact()],
  onError: (event) => {
    console.debug("BUGSNAG ERROR", event.errors[0]);

    if (hasIgnoredClass(event.errors[0].errorClass)) {
      return false;
    }

    if (hasIgnoredText(event.errors[0].errorMessage)) {
      return false;
    }

    const isExternal = event.errors[0].stacktrace.find(({ file }) => {
      return !file.startsWith(`${window.location.origin}/static/`);
    });

    if (isExternal) {
      return false;
    }

    event.addMetadata("errors", event.errors);

    return true;
  },
});

export default Bugsnag;
