import RoutesComponents from "components/RoutesComponents";
import ScrollToTop from "components/UI/ScrollToTop";
import React, { Suspense, useRef } from "react";
import { useHeaderScroll } from "./hooks/useHeaderScroll";
import { InfoBar } from "components/alerts/InfoBar";
import { PortableVideo } from "components/UI/PortableVideo";
import "./AppContent.scss";

export const AppContent = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useHeaderScroll({ wrapperRef });

  return (
    <ScrollToTop>
      <div className="Wrapper" id="scrollableDiv" ref={wrapperRef}>
        <div className="routeWrapper">
          <Suspense><InfoBar /></Suspense>
          <PortableVideo />
          <RoutesComponents />
        </div>
      </div>
    </ScrollToTop>
  );
};
