import {
  ICollection,
  ICollectionSearchResults,
  IGifList,
  ICurrentCollectionSearchParams,
  IEditCollectionParams,
  ICreateCollectionParams,
  IAddGifParamsToCollection
} from "./types";
import AuthHttpService from "./AuthHttpService";

export default class CollectionService extends AuthHttpService {
  public constructor(readonly url?: string) {
    super(url);
  }

  async getUserCollections(params: {
    page: number;
    count: number;
    userId?: string;
  }): Promise<ICollectionSearchResults> {
    return await this.get(`v2/users/${params.userId}/collections?count=${params.count}&page=${params.page}`);
  }

  async getMyCollections(params: {
    page: number;
    count: number;
  }): Promise<ICollectionSearchResults> {
    return await this.get(`v2/me/collections?count=${params.count}&page=${params.page}`);
  }

  async getMyCollectionById(params: ICurrentCollectionSearchParams) {
    return await this.get(`v2/me/collections/${params.collectionId}`);
  }

  async getCollectionById(params: {
    userName: string;
    collectionId: string;
  }): Promise<ICollection> {
    return await this.get(`v2/users/${params.userName}/collections/${params.collectionId}`);
  }

  async getMyCollection(params: {
    collectionId: string;
  }): Promise<ICollection> {
    return await this.get(`v2/me/collections/${params.collectionId}`);
  }

  async getCollectionItems(params: {
    userId: string;
    collectionId: string;
    page: number;
    count: number;
  }): Promise<IGifList> {
    return await this.get(
      `v2/users/${params.userId}/collections/${params.collectionId}/gifs?count=${params.count}&page=${params.page}`
    );
  }

  async getMyCollectionItems(params: {
    collectionId: string;
    page: number;
    count: number;
  }): Promise<IGifList> {
    return await this.get(`v2/me/collections/${params.collectionId}/gifs?count=${params.count}&page=${params.page}`);
  }

  async createCollection(params: ICreateCollectionParams) {
    return await this.post("/v2/me/collections", params);
  }

  async editCollection(params: IEditCollectionParams) {
    return await this.patch(`v2/me/collections/${params.collectionId}`, params);
  }

  async deleteCollectionItems(params: {
    collectionId: string;
    gifsIds: string[];
  }) {
    return await this.delete(`v2/me/collections/${params.collectionId}/gifs`, params.gifsIds);
  }

  async deleteCollection(params: {
    collectionId: string;
  }) {
    return await this.delete(`/v2/me/collections/${params.collectionId}`);
  }

  async addGifToCollection(params: IAddGifParamsToCollection) {
    return await this.post(`v2/me/collections/${params.collectionId}/gifs`, {
      gifId: params.gifId,
      context: params.context,
    });
  }
}
