import AuthHttpService from "./AuthHttpService";
import {
  IBulkSubmitResponse,
  ICreateGalleryData,
  ICreateGalleryResponse,
  IGalleryStatusResponse,
  ISubmitParams,
  ISubmitResponse,
  IUploadBatchResponse, IUploadStatusResponse,
  IUploadTicketParams,
  IUploadTicketResponse
} from "services/types";

class ApiError extends Error {
  readonly status?: number;
  readonly response?: string | object | null;

  constructor(message?: string, data?: { status: number; response: string | object | null }) {
    super(message);

    this.name = "ApiError";

    if (typeof data === "object") {
      this.status = data.status;
      this.response = data.response;
    }
  }
}

interface IBulkSubmitRequest {
  files: string[];
}

export default class UploadService extends AuthHttpService {
  public constructor(readonly url?: string) {
    super(url);
  }

  async getUploadTicket({ md5, url }: IUploadTicketParams): Promise<IUploadTicketResponse> {
    return await this.post("v2/upload", { md5, url, timeline: true });
  }

  async getUploadTicketBatch(count: number): Promise<IUploadBatchResponse> {
    return await this.post("v2/upload/batch", { count });
  }

  async getTicketStatus({ id }: { id: string }): Promise<IUploadTicketResponse> {
    return await this.get(`v2/upload/${id}/status`);
  }

  async checkUploadStatus(gifId: string) {
    return await this.get<Promise<IUploadStatusResponse>>(`v1/gifs/fetch/status/${gifId}`);
  }

  async createGallery(data: ICreateGalleryData): Promise<ICreateGalleryResponse> {
    return await this.post("v2/gallery", data);
  }

  async checkUploadGalleryStatus(galleryId: string) {
    return await this.get<Promise<IGalleryStatusResponse>>(`v2/gallery/${galleryId}/status`);
  }

  putFile({ url, file, callback }: { url: string; file: File; callback: Function | undefined }) {
    // eslint-disable-next-line
    console.log(`Putting a file to ${url}`);

    const xhr = new XMLHttpRequest();

    xhr.open("PUT", url);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.setRequestHeader("Content-Type", file.type);
    xhr.responseType = "json";

    xhr.upload.onprogress = progressEvent => {
      if (progressEvent.lengthComputable && callback) {
        callback(progressEvent);
      }
    };

    return new Promise((resolve, reject) => {
      xhr.onload = progressEvent => {
        const request = progressEvent.target as XMLHttpRequest;
        const { status, statusText } = request;
        const response = request.response as string | object | null;

        // eslint-disable-next-line
        if (status >= 300) {
          return reject(
            new ApiError(`Network error: ${statusText}`, {
              status,
              response
            })
          );
        }

        if (response) {
          // eslint-disable-next-line
          console.log("putFile response:", response);
        }

        return resolve(response);
      };

      xhr.onerror = progressEvent => {
        const request = progressEvent.target as XMLHttpRequest;
        const { status, statusText } = request;
        const response = request.response as string | object | null;

        return reject(
          new ApiError(`Network error: ${statusText}`, {
            status,
            response
          })
        );
      };

      xhr.send(file);
    });
  }

  async submitGifs(params: IBulkSubmitRequest): Promise<IBulkSubmitResponse> {
    return await this.post("v2/gifs/submit/bulk", {
      files: params.files,
    });
  }

  async submitGif({
    ticketID,
    tags,
    niches,
    isPrivate,
    sexuality,
    keepAudio,
    description,
    startTime,
    endTime
  }: ISubmitParams): Promise<ISubmitResponse> {
    return await this.post("v2/gifs/submit", {
      ticket: ticketID,
      tags,
      private: isPrivate,
      keepAudio,
      description,
      niches,
      sexuality,
      cut: {
        start: startTime,
        duration: endTime - startTime
      }
    });
  }
}
