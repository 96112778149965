import React from "react";
import { createRoot } from "react-dom/client";
import { reportLCP } from "utils/reportLCP";
import { AppContainer } from "components/app/AppContainer";
import "./bugsnag";

const root = createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<AppContainer />);

reportLCP();
