import { Dispatch, SetStateAction } from "react";
import { routes } from "routes";
import { useNavigate } from "react-router-dom";
import { useQuery } from "hooks/useQuery";
import { useAppStore } from "store/appStore";

import "./styles/Tag.scss";

interface ITagProps {
  title: string;
  isLast?: boolean;
  setSearchInput: Dispatch<SetStateAction<string>>;
  setCurrentTag: Dispatch<SetStateAction<string[]>>;
}

export const Tag = ({ title, isLast, setSearchInput, setCurrentTag }: ITagProps) => {
  const navigate = useNavigate();
  const hideAllMenus = useAppStore((state) => state.hideAllMenus);
  const query = useQuery();

  const handleClickSearchItem = () => {
    setSearchInput("");
    setCurrentTag(prev => {
      if (prev.includes(title)) {
        navigate(routes.multipleTag(prev, query.get("order") ?? undefined, !!query.get("verified")));
        return prev;
      }

      if (prev.length >= 3) {
        return prev;
      }

      navigate(routes.multipleTag([...prev, title], query.get("order") ?? undefined, !!query.get("verified")));
      return [...prev, title];
    });
    document?.getElementById("global-search-input")?.blur();
    hideAllMenus();
  };

  const handleClickLastTag = () => {
    navigate(routes.trendingTags());
    setSearchInput("");
    setCurrentTag([]);
    hideAllMenus();
  };

  const goTo = isLast ? handleClickLastTag : handleClickSearchItem;

  return (
    <li className="Tag" onClick={goTo}>
      <button aria-label={`${title} tag`}>{title}</button>
    </li>
  );
};
