import AuthHttpService from "./AuthHttpService";

import {
  ITag,
  ITagInfo,
  ITagList,
  ITagSuggestedOptions,
} from "./types";

interface IGetRecommendedParams {
  gifId: string;
  page?: number;
}

export interface IGetMatchedTagsParams {
  query: string;
}

export default class TagsService extends AuthHttpService {
  public constructor(readonly url?: string) {
    super(url);
  }

  async getTags(): Promise<ITagList> {
    return await this.get("v1/tags");
  }

  async getTrendingTags(): Promise<ITag[]> {
    const res = await this.get<Promise<{tags: ITag[]}>>("v2/search/trending");
    return res.tags;
  }

  async getRecommendedTags(params: IGetRecommendedParams) {
    return await this.get(`v2/recommend/tags/${params.gifId}?page=${params.page}`);
  }

  async getTagInfo(id: string): Promise<ITagInfo> {
    return await this.get(`v2/tags/${this.encodeComponent(id)}`);
  }

  async getTagSuggested({ count, query }: ITagSuggestedOptions): Promise<string[]> {
    const params = new URLSearchParams();

    if (query) {
      params.append("query", query);
    }

    if (count) {
      params.append("count", count);
    }

    return await this.get(`v2/tags/suggest?${params.toString()}`);
  }

  getMatchedTags({ query }: IGetMatchedTagsParams): Promise<string[]> {
    const queryParams = new URLSearchParams();
    queryParams.append("query", query);
    return this.get(`v1/tags/match?${queryParams.toString()}`);
  }
}
