// See also: src/components/RoutesComponents.tsx

import { encodeTag, encodeTags } from "utils/strings";
import { TabEnum } from "components/kinde/Tabs";

interface IUrlParams {
  [key: string]: string | undefined | boolean;
}

export interface ISearchParams extends IUrlParams {
  order?: string;
  verified?: boolean;
  query?: string;
  viewMode?: "tiles" | "preview";
}

export enum FreetypeSearchType {
  gifs = "gifs",
  images = "images",
  creators = "creators",
  niches = "niches"
}

const buildUrl = (path: string, params?: IUrlParams) => {
  if (!params) {
    return path;
  }

  const queryParams = new URLSearchParams();

  for (const key in params) {
    let value = params[key];
    if (typeof value === "boolean") {
      value = value ? "1" : "";
    }

    if (!value) {
      continue;
    }

    queryParams.append(key, value);
  }

  return `${path}${queryParams.toString().length ? `?${queryParams.toString()}` : ""}`;
};

export const routes = {
  accountSettings: () => "/settings/account",
  collection: (userId: string, id: string) => `/users/${userId}/collections/${id}`,
  crash: () => "/crash",
  create: () => "/create",
  dashboard: () => "/dashboard",
  dmca: () => "/dmca",
  embed: (id: string) => `/ifr/${id}`,
  explore: () => "/explore",
  exploreCreators: (order?: string, verified?: boolean) => buildUrl("/creators", { order, verified }),
  exploreImages: (order?: string, verified?: boolean) => buildUrl("/explore/images", { order, verified }),
  exploreTop: (order?: string, verified?: boolean) => buildUrl("/explore/top", { order, verified }),
  favourites: () => "/gifs/favourites",
  faq: () => "/faq",
  following: () => "/following",
  followers: () => "/followers",
  guidelines: () => "/guidelines",
  home: (videoId?: string) => `/${videoId ? (`?video=${videoId}`) : ""}`,
  manageProfile: (tab?: string, count?: string) => {
    const queryParams = new URLSearchParams();

    if (tab) {
      queryParams.append("tab", tab);
    }

    if (count) {
      queryParams.append("count", count);
    }

    const params = queryParams.toString().length ? `?${queryParams.toString()}` : "";

    return `/profile/manage${params}`;
  },
  migrateImgur: () => "/migrate/imgur",
  migrateImgurConfirm: () => "/migrate/imgur/confirm",
  migrateImgurSuccess: () => "/migrate/imgur/success",
  myNiches: () => "/niches/my",
  niche: (id: string, order?: string) => buildUrl(`/niches/${id}`, { order }),
  niches: () => "/niches",
  privacyPolicy: () => "/privacy",
  profileSettings: () => "/settings/profile",
  signup: () => "/signup",
  singleTag: (tag: string, order?: string, verified?: boolean, tab?: string) =>
    buildUrl(`/gifs/${encodeTag(tag)}`, { order, verified, tab }),
  multipleTag: (tags: string[], order?: string, verified?: boolean, tab?: string) =>
    buildUrl(`/gifs/${encodeTags(tags)}`, { order, verified, tab }),
  search: (query?: string) => buildUrl("/search", { query }),
  freetypeSearch: (type?: FreetypeSearchType, params?: ISearchParams) => buildUrl(`/search/${type ?? ":type"}`, params),
  statement2257: () => "/notice2257",
  sudo: () => "/sudo",
  terms: () => "/terms",
  trendingTags: () => "/categories",
  trendingTagsA: (a: string) => `/categories/${a}`,
  unsubscribed: () => "/unsubscribed",
  user: (id: string, order?: string) => buildUrl(`/users/${id}`, { order }),
  userCollections: (userId: string) => `/users/${userId}/collections`,
  verify: () => "/user/verify",
  authorize: () => "/authorize",
  experiments: () => "/experiments",
  watch: (id: string, rel?: string, source?: string, order?: string, verified?: boolean) => {
    const relPart = rel ? `#rel=${encodeURIComponent(rel)}` : "";
    const sourcePart = source ? `?source=${source}` : "";
    const orderPart = order ? `;order=${order}` : "";
    const verifiedPart = verified ? `;verified=${verified}` : "";
    const resultPart = relPart ? `${relPart}${orderPart}${verifiedPart}` : `${sourcePart}`;

    return `/watch/${id}${resultPart}`;
  },
  manageAdSlots: () => "/admin/ads",
  texasSlatePage: () => "/texas-slate",
  searchWarningPage: () => "/search-warning",
  userSignIn: () => "/auth/signin",
  userSignUp: () => "/auth/signup",
};
