import SealCheckIcon from "components/icons/bold/seal-check.svg?react";
import SealCheckStudioIcon from "components/icons/bold/seal-check-studio.svg?react";
import "./VerifiedIcon.scss";

interface IVerifiedIcon {
  isStudio?: boolean;
  title?: string;
}

export const VerifiedIcon = ({ isStudio, title }: IVerifiedIcon) => {
  return (
    <span
      title={title}
      className="VerifiedIcon"
    >
      {isStudio ? <SealCheckStudioIcon /> : <SealCheckIcon />}
    </span>
  );
};
