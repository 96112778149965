import { useEffect, useCallback, useRef } from "react";
import { mainBus, metricsBus } from "buses";
import fscreen from "fscreen";
import { useLocation } from "react-router-dom";
import { useAppStore } from "store/appStore";

export const useFullScreen = () => {
  const isFullScreen = useAppStore((state) => state.isFullScreen);
  const isForceFS = useAppStore((state) => state.isForceFS);
  const toggleForceFS = useAppStore((state) => state.toggleForceFS);
  const toggleFullScreen = useAppStore((state) => state.toggleFullScreen);
  const playingGif = useRef<string|null>(null);
  const location = useLocation();

  const scrollIntoView = useCallback(() => {
    if (playingGif.current === null) {
      return;
    }

    document.querySelector(`#${playingGif.current}`)?.scrollIntoView();
  }, []);

  const handleFullscreenChange = useCallback(() => {
    const isInFullScreen = fscreen.fullscreenElement !== null;
    toggleFullScreen(isInFullScreen);
    if (isInFullScreen) {
      console.debug("Entered fullscreen mode");
    }

    isInFullScreen ? metricsBus.emit("fullscreenEntered") : metricsBus.emit("fullscreenExited");
  }, []);

  const handleFullscreenError = useCallback((e?: Event) => {
    console.debug("Fullscreen Error", e);
  }, []);

  useEffect(() => {
    if (fscreen.fullscreenEnabled) {
      fscreen.addEventListener("fullscreenchange",handleFullscreenChange, false);
      fscreen.addEventListener("fullscreenerror", handleFullscreenError, false);
      window.addEventListener("resize", scrollIntoView, false);

      return () => {
        fscreen.removeEventListener("fullscreenchange", handleFullscreenChange);
        fscreen.removeEventListener("fullscreenerror", handleFullscreenError);
        window.removeEventListener("resize", scrollIntoView);
      };
    }
  }, [handleFullscreenChange, handleFullscreenError, scrollIntoView]);

  useEffect(() => {
    if (isFullScreen) {
      mainBus.emit("clear_streamate_cameras");
    }

    scrollIntoView();
  }, [isFullScreen, scrollIntoView]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!isFullScreen) {
        return;
      }

      if (playingGif.current === null) {
        return;
      }

      const gifElement = document.querySelector<HTMLDivElement>(`#${playingGif.current}`);

      if (!gifElement) {
        return;
      }

      const parent = gifElement.parentElement;

      if (!parent) {
        return;
      }

      switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        parent.scrollBy({
          top: window.innerHeight,
          behavior: "smooth",
        });
        break;

      case "ArrowUp":
        event.preventDefault();
        parent.scrollBy({
          top: -window.innerHeight,
          behavior: "smooth",
        });
        break;

      default:
        break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isFullScreen]);

  useEffect(() => {
    return () => {
      if (!fscreen.fullscreenEnabled || isForceFS) {
        toggleFullScreen(false);
      }

      if (document.fullscreenElement && document.hasFocus()) {
        try {
          fscreen.exitFullscreen();
        } catch (e) {
          console.error(`Could not exit fullscreen: ${e}`);
        }
      }
    };
  }, [toggleFullScreen, location, isForceFS]);

  useEffect(() => {
    const enterFullScreen = () => {
      try {
        const promise = fscreen.requestFullscreen(document.body);

        // Some browsers throw exceptions, some return a promise,
        // need to handle both.

        // @ts-ignore
        if (promise instanceof Promise) {
          promise.catch((e: Error) => {
            console.error(`Could not enter fullscreen: ${e}`);
          });
        }
      } catch (e) {
        console.error(`Could not enter fullscreen: ${e}`);
      }
    };

    const exitFullScreen = () => {
      if (document.hasFocus() && document.fullscreenElement) {
        try {
          fscreen.exitFullscreen();
        } catch (e) {
          console.error(`Could not exit fullscreen: ${e}`);
        }
      }
    };

    const toggleFullscreen = ({ id, isForceFS }: {id: string; isForceFS?: boolean}) => {
      playingGif.current = `gif_${id}`;

      if (fscreen.fullscreenEnabled && !isForceFS) {
        isFullScreen ? exitFullScreen() : enterFullScreen();
      } else if (isForceFS && isFullScreen) {
        toggleFullScreen(false);
        toggleForceFS(false);
        metricsBus.emit("forceFullscreenExited");
      } else {
        toggleFullScreen(!isFullScreen);
      }

      if (!fscreen.fullscreenEnabled) {
        isFullScreen ? metricsBus.emit("fullscreenExited") : metricsBus.emit("fullscreenEntered");
      }
    };

    const setPlayingGif = ({ id }: {id: string}) => {
      playingGif.current = `gif_${id}`;
    };

    mainBus.on("toggle_fullscreen", toggleFullscreen);
    mainBus.on("set_playing_gif", setPlayingGif);

    return () => {
      mainBus.off("toggle_fullscreen", toggleFullscreen);
      mainBus.off("set_playing_gif", setPlayingGif);
    };
  }, [isFullScreen, toggleFullScreen, toggleForceFS]);
};
