import { apiMain } from "services/index";
import { Variants } from "@amplitude/experiment-js-client";
import { loadExperiments, loadFirstPageUrl } from "utils/storage";
import amplitudeExperiments from "services/amplitude/amplitudeExperiments";
import { bus } from "buses/metrics/bus";
import { IExperimentList } from "services/types";
import { isTrackingEnabled } from "utils/tracking";
import sessionService from "services/session/sessionService";
import { getDeviceType, getWindowWidth } from "utils/device";

interface IVariants {
  [key: string]: string | boolean | null;
}

class ExperimentService {
  private variants: null | IVariants = null;
  private exposures: string[] = [];
  private fetchPromise: Promise<[IExperimentList, Variants]> | null = null;

  async fetchVariants(force: boolean = false) {
    if (force) {
      this.clearVariants();
    }

    if (this.variants !== null) {
      return this.variants;
    }

    try {
      if (this.fetchPromise === null) {
        this.fetchPromise = this.createFetchPromise();
      }

      const [internalVariants, remoteVariants] = await this.fetchPromise;

      this.fillVariants(internalVariants, remoteVariants);
    } catch (e) {
      console.error(e);
    } finally {
      this.fetchPromise = null;
    }
  }

  async getVariant(experimentKey: string, fallback: string | boolean, coverage: number = 1) {
    const experimentEnabled = loadExperiments() !== null || await isTrackingEnabled(coverage);
    if (!experimentEnabled) {
      return fallback;
    }

    const variants = await this.getAllVariants();
    const variant = variants[experimentKey] ?? null;

    if (variant !== null) {
      if (!this.exposures.includes(experimentKey)) {
        bus.emit("experimentVariantExposure", {
          experimentKey,
          experimentVariant: variant
        });

        this.exposures.push(experimentKey);
      }

      return variant;
    }

    return fallback;
  }

  async getAllVariants(): Promise<IVariants> {
    if (this.variants === null) {
      await this.fetchVariants();
    }

    const localVariants = loadExperiments<IVariants>() ?? {};
    const fetchedVariants = this.variants ?? {};

    return {
      ...fetchedVariants,
      ...localVariants
    };
  }

  clearVariants() {
    amplitudeExperiments.clear();
    this.variants = null;
    this.exposures = [];
  }

  private fillVariants(internalVariants: IVariants, remoteVariants: Variants) {
    this.variants = {};

    for (const key in internalVariants) {
      this.variants[key] = internalVariants[key];
    }

    for (const key in remoteVariants) {
      this.variants[key] = remoteVariants[key].value ?? null;
    }
  }

  private async createFetchPromise(): Promise<[IExperimentList, Variants]> {
    await amplitudeExperiments.start().catch((reason) => {
      console.debug(reason);
    });

    const deviceId = (await sessionService.getSession()).session;
    const firstPageURL = loadFirstPageUrl() ?? window.location.pathname;
    const landedToWatchPage = firstPageURL.startsWith("/watch/");

    return await Promise.all([
      apiMain.getExperiments(),
      amplitudeExperiments.fetch({
        device_id: deviceId,
        user_properties: {
          "Device Type": getDeviceType(getWindowWidth()),
          "Landed to Watch Page": landedToWatchPage
        }
      }).then(client => client.all())
    ]);
  }
}

export default new ExperimentService();
