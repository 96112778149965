import { metricsBus } from "buses";
import gaMetrics from "services/ga/gaMetrics";

metricsBus.on("gifView", (props) => {
  gaMetrics.sendGifView(props);
});

metricsBus.on("meaningfulGifView", (props) => {
  gaMetrics.sendMeaningfulGifView(props);
});

metricsBus.on("gifReplay", (props) => {
  gaMetrics.sendGifReplay(props);
});

metricsBus.on("uploadSelected", ({ type }) => {
  switch (type) {
  case "video":
    gaMetrics.sendUploadVideoSelected();
    break;
  case "image":
    gaMetrics.sendUploadImageSelected();
    break;
  case "bulkImages":
    gaMetrics.sendUploadBulkClick();
    break;
  default:
    console.error(`[GA] Undefined upload type: ${type}`);
  }
});

metricsBus.on("uploadStarted", () => {
  gaMetrics.sendUploadStarted();
});

metricsBus.on("uploadTagSelectionEnded", () => {
  gaMetrics.sendUploadTagsSelected();
});

metricsBus.on("uploadEnded", () => {
  gaMetrics.sendUploadPublished();
});

metricsBus.on("gifClicked", (props) => {
  gaMetrics.sendGifClick(props);
});

metricsBus.on("gifLiked", (props) => {
  gaMetrics.sendLiked(props);
});

metricsBus.on("addedToCollection", (props) => {
  gaMetrics.sendAddedToCollection(props);
});

metricsBus.on("fullscreenEntered", () => {
  gaMetrics.sendEnterFullscreen();
});

metricsBus.on("adLoaded", (props) => {
  gaMetrics.sendAdLoaded(props);
});

metricsBus.on("infoBarClicked", () => {
  gaMetrics.sendInfobarCta();
});

metricsBus.on("infoBarClosed", () => {
  gaMetrics.sendInfobarDismiss();
});

metricsBus.on("sharedFixedEmbed", () => {
  gaMetrics.sendShareEmbedFixed();
});

metricsBus.on("sharedResponsiveEmbed", () => {
  gaMetrics.sendShareEmbedResponsive();
});

metricsBus.on("shareClick", () => {
  gaMetrics.sendShareClick();
});

metricsBus.on("shareCopy", () => {
  gaMetrics.sendShareCopy();
});

metricsBus.on("shareEmbed", () => {
  gaMetrics.sendShareEmbed();
});

metricsBus.on("shareTwitter", () => {
  gaMetrics.sendShareTwitter();
});

metricsBus.on("shareReddit", () => {
  gaMetrics.sendShareReddit();
});

metricsBus.on("shareDiscord", () => {
  gaMetrics.sendShareDiscord();
});

metricsBus.on("optionClicked", () => {
  gaMetrics.sendOptionClick();
});

metricsBus.on("pinnedContentClicked", () => {
  gaMetrics.sendPinnedContentClick();
});

metricsBus.on("menuButtonClicked", (props) => {
  gaMetrics.sendMenuButtonClicked(props);
});

metricsBus.on("followUserClicked", (props) => {
  gaMetrics.sendFollowUserClicked(props);
});

metricsBus.on("socialProfileClicked", (props) => {
  gaMetrics.sendSocialProfileClick(props);
});

metricsBus.on("tagClicked", (props) => {
  gaMetrics.sendTagClicked(props);
});

metricsBus.on("signUpViewed", () => {
  gaMetrics.sendSignUpFormView();
});

metricsBus.on("signUpSuccess", () => {
  gaMetrics.sendSignUpSuccessful();
});

metricsBus.on("scrollPromptViewed", () => {
  gaMetrics.sendScrollPromptShow();
});

metricsBus.on("scrollPromptClosed", () => {
  gaMetrics.sendScrollPromptDismiss();
});

metricsBus.on("searched", (props) => {
  gaMetrics.sendSearch(props);
});

metricsBus.on("uploadVideoTrimmed", () => {
  gaMetrics.sendUploadVideoTrim();
});

metricsBus.on("uploadGetVerifiedClicked", () => {
  gaMetrics.sendUploadGetVerifiedClicked();
});

metricsBus.on("uploadVideoConfirmed", () => {
  gaMetrics.sendUploadVideoConfirmed();
});

metricsBus.on("uploadImageConfirmed", () => {
  gaMetrics.sendUploadImageConfirmed();
});

metricsBus.on("uploadMultipleConfirmed", () => {
  gaMetrics.sendUploadMultipleConfirmed();
});

metricsBus.on("uploadAudienceDefined", () => {
  gaMetrics.sendUploadAudienceDefined();
});

metricsBus.on("uploadUrlSelected", () => {
  gaMetrics.sendUploadUrlSelected();
});

metricsBus.on("uploadImagesDropped", () => {
  gaMetrics.sendUploadImagesDropped();
});

metricsBus.on("uploadImageDropped", () => {
  gaMetrics.sendUploadImageDropped();
});

metricsBus.on("uploadVideoDropped", () => {
  gaMetrics.sendUploadVideoDropped();
});

metricsBus.on("uploadFinished", (props) => {
  gaMetrics.sendUploadFinisher(props);
});

metricsBus.on("uploadEncodingStarted", () => {
  gaMetrics.sendEncodingStarted({});
});

metricsBus.on("uploadEncodingFinished", () => {
  gaMetrics.sendEncodingFinished();
});

metricsBus.on("gifWatched120s", () => {
  gaMetrics.sendWatch120s();
});

metricsBus.on("profileViewed", (props) => {
  gaMetrics.sendProfileView(props);
});

metricsBus.on("errorLoadingGifs", () => {
  gaMetrics.sendErrorLoadingGifs();
});

metricsBus.on("voteUpAddClicked", () => {
  gaMetrics.sendVoteUpAddClick();
});

metricsBus.on("voteUpRemoveClicked", () => {
  gaMetrics.sendVoteUpRemoveClick();
});

metricsBus.on("voteDownAddClicked", () => {
  gaMetrics.sendVoteDownAddClick();
});

metricsBus.on("voteDownRemoveClicked", () => {
  gaMetrics.sendVoteDownRemoveClick();
});

metricsBus.on("privateSessionStarted", () => {
  gaMetrics.sendSessionStartPrivate();
});

metricsBus.on("tagFeedbackEnabledOptions", () => {
  gaMetrics.sendTagFeedbackEnabledOptions();
});

metricsBus.on("tagFeedbackEnabled", () => {
  gaMetrics.sendTagFeedbackEnabled();
});
