import { useMemo } from "react";

interface IProps {
  src?: string;
}

export const useVideo = ({ src = "" }: IProps) => {
  const isHls = useMemo(() => {
    return src.substring(src.lastIndexOf(".")) === ".m3u8";
  }, [src]);

  return {
    isHls
  };
};
