import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "routes";
import { useExperiment } from "hooks/useExperiment";
import { EXPERIMENT_TEXAS_SLATE } from "services/experiment/constants";

declare global {
  interface Window {
    is_texan: boolean;
  }
}

export const useTexasSlate = () => {
  const { variant: texasSlate } = useExperiment({ key: EXPERIMENT_TEXAS_SLATE, fallback: false });
  const isTexas = window.is_texan || texasSlate;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const allowedPages = location.pathname.includes("/texas-slate") || location.pathname.includes("/guidelines");

    if (isTexas && !allowedPages) {
      navigate(routes.texasSlatePage());
    }
  }, [location.pathname, navigate, texasSlate]);
};
