import { useAppStyles } from "./hooks/useAppStyles";
import { useGetUserData } from "./hooks/useGetUserData";
import { useCheckPreferences } from "./hooks/useCheckPreferences";
import { useCheckExternalMessages } from "./hooks/useCheckExternalMessages";
import { useWindowHistoryByMouse } from "./hooks/useWindowHistoryByMouse";
import { useInitDocumentTitle } from "./hooks/useInitDocumentTitle";
import { useHideAllMenus } from "./hooks/useHideAllMenus";
import { useFirstPageTracker } from "./hooks/useFirstPageTracker";
import { useFullScreen } from "./hooks/useFullScreen";
import { useCookieNotice } from "./hooks/useCookieNotice";
import { useStreamateCameras } from "./hooks/useStreamateCameras";
import { useTexasSlate } from "./hooks/useTexasSlate";
import { useTrackUser } from "./hooks/useTrackUser";

export const AppHooks = () => {
  useGetUserData();
  useCheckPreferences();
  useCheckExternalMessages();
  useTexasSlate();

  useFirstPageTracker();
  useAppStyles();
  useWindowHistoryByMouse();
  useInitDocumentTitle();
  useHideAllMenus();
  useFullScreen();
  useCookieNotice();
  useStreamateCameras();
  useTrackUser();

  return null;
};
