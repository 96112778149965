import { PropsWithChildren, useEffect } from "react";
import classes from "./Backdrop.module.scss";

export const Backdrop = ({ children }: PropsWithChildren) => {
  useEffect(() => {
    if (!document.body) {
      return;
    }

    document.body.classList.add("noScroll");

    return () => {
      document.body.classList.remove("noScroll");
    };
  }, []);

  return (
    <div className={classes.Backdrop}>{children}</div>
  );
};
