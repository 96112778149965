import mitt from "mitt";

type GifPlaybackEvent = {
  id: string;
  duration: number;
};

type MainBusEvents = {
  gif_view: string;
  gif_playback: GifPlaybackEvent;
  set_playing_gif: { id: string };
  toggle_fullscreen: { id: string; isForceFS?: boolean };
  add_streamate_camera: number;
  clear_streamate_cameras: void;
};

export const bus = mitt<MainBusEvents>();
