export const getHashValue = (key: string, path?: string): string|undefined => {
  try {
    const hash = path ?? decodeURIComponent(window.location.hash);

    if (hash.indexOf("#") !== 0) {
      return undefined;
    }

    const parts = hash.substr(1).split(";");

    for (const part of parts) {
      const kv = part.split("=");

      if (kv[0] === key && kv.length > 1) {
        return kv[1];
      }
    }

    return undefined;
  } catch (e) {
    return undefined;
  }
};

export const createApiUrl = (path: string): string => {
  // TODO: trim slashes.
  return `${import.meta.env.REACT_APP_API_BASE_URL}${path}`;
};

export const createHlsUrl = (gifId: string, quality: string): string => {
  return `${import.meta.env.REACT_APP_API_BASE_URL}v2/gifs/${gifId}/${quality}.m3u8`;
};
