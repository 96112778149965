import { BaseHttpService } from "./BaseHttpService";

export interface IGetModelsResult {
  models: IModel[];
  count: number;
  total: number;
}

export interface IModel {
  id: number;
  username: string;
  avatarUrl: string;
  previewUrl: string;
  stream: IModelStream;
}

interface IModelStream {
  width: number;
  height: number;
  url: string;
  urls: IModelStreamUrls;
}

interface IModelStreamUrls {
  "160p": string;
  "240p": string;
  "480p": string;
  original: string;
}

export interface IGetModelsParams {
  [key: string]: string;
  /**
   * After applying all filters and sorting cut response to limit models.
   * If limit = "0" then unlimited.
   * If limit <0 or limit is NaN limit = 10
   * Default value : 10
   */
  limit: string;
}

export default class StripChatService extends BaseHttpService {
  public constructor() {
    super(import.meta.env.REACT_APP_STRIPCHAT_URL);
  }

  public getModels(params?: IGetModelsParams): Promise<IGetModelsResult> {
    return this.get(`/api/models?${new URLSearchParams(params).toString()}`);
  }
}
